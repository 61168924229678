import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MySnackBar } from '@src/components/MySnackBar';
import { ReservationsComponent1 } from '@src/components/Reservations/component';
import { RESERVATIONS_CONNECTION } from '@src/generated/graphql';
import gql from 'graphql-tag';
import queryString from 'query-string';

const reservationsQuery = gql`
  query RESERVATIONS_CONNECTION($first: Int, $skip: Int) {
    reservationsConnection(first: $first, skip: $skip) {
      item {
        id
        startTime
        endTime
        returnTime
        token
        status
        paymentStatus
        user {
          id
          firstname
          lastname
          phoneNo
          deletedAt
          chats {
            id
          }
        }
        startPort {
          id
          name
        }
        port {
          id
          name
        }
        door {
          id
          doorIndex
          status
        }
        babyCar {
          id
          code
          status
        }
        taxedAmount
        issuedCoupon {
          id
          createdAt
          coupon {
            id
            discountAmount
            name
          }
        }
        startDoor {
          id
          doorIndex
          status
        }
        product {
          name
        }
        extendedTimes
        paymentHistories {
          status
        }
      }
      total
    }
  }
`;

const UNLOCK = gql`
  mutation UNLOCK($token: String!) {
    unlock(token: $token)
  }
`;

const ADMINLOCK = gql`
  mutation ADMINLOCK($token: String!) {
    adminLock(token: $token)
  }
`;
const FINISH = gql`
  mutation FINISH($reservationId: ID!) {
    finishReservation(reservationId: $reservationId) {
      id
    }
  }
`;

const ADMIN_LOCK_RESERVATION = gql`
  mutation ADMIN_LOCK_RESERVATION($portId: ID!, $doorId: ID!) {
    adminLockReservation(portId: $portId, doorId: $doorId) {
      id
      token
    }
  }
`;

const UPDATE_RESERVATION = gql`
  mutation UPDATE_RESERVATION(
    $reservationId: ID!
    $data: ReservationUpdateInput!
  ) {
    updateReservation(reservationId: $reservationId, data: $data) {
      paymentStatus
    }
  }
`;

const CREATE_CHAT = gql`
    mutation CREATE_CHAT(
        $userId: ID!
    ) {
        createChat(userId: $userId) {
            id
            createdAt
            updatedAt
            name
            lastSeenByAdmin
            lastSeenByUser
            latestMessageTime
        }
    }
`;

export const ReservationsComponent = (props: any) => {
  const search = queryString.parse(location.search);
  let page = 1;
  if (!Array.isArray(search.page)) {
    page = parseInt(search.page || '1', 10) - 1;
  }
  let rowsPerPage = 1;
  if (!Array.isArray(search.rowsPerPage)) {
    rowsPerPage = parseInt(search.rowsPerPage || '25', 10);
  }

  const {
    loading: reservationsLoading,
    error: reservationsError,
    data: reservationsData,
    refetch: reservationsRefetch,
  } = useQuery<RESERVATIONS_CONNECTION>(reservationsQuery, {
    variables: {
      first: (rowsPerPage || 5) * 1,
      skip: page == 0 ? 0 : page * (rowsPerPage || 5) * 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { loading: allLoading, data: reservationsAllData } =
    useQuery<RESERVATIONS_CONNECTION>(reservationsQuery, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });
  const [unlockMutation] = useMutation(UNLOCK);
  const [adminLockMutation] = useMutation(ADMINLOCK);
  const [finishMutation] = useMutation(FINISH);
  const [adminLockReservationMutation] = useMutation(ADMIN_LOCK_RESERVATION);
  const [updateReservationMutation] = useMutation(UPDATE_RESERVATION);
  const [createChatMutation] = useMutation(CREATE_CHAT);

  <CircularProgress />;
  if (reservationsLoading && allLoading) return <div>Loading...</div>;

  if (reservationsError) {
    <MySnackBar
      variant="error"
      message={reservationsError.graphQLErrors[0].message}
    />;
  }
  {
    search;
  }
  let searchedPage = 1;
  if (!Array.isArray(search.page)) {
    searchedPage = parseInt(search.page || '1', 10);
  }
  page = searchedPage - 1;
  let searchedRowsPerPage = 1;
  if (!Array.isArray(search.rowsPerPage)) {
    searchedRowsPerPage = parseInt(search.rowsPerPage || '25', 10);
  }
  rowsPerPage = searchedRowsPerPage * 1;

  const onChangePage = (_event, pageNumber) => {
    const search = queryString.parse(location.search);
    search.page = pageNumber + 1;
    props.history.push({
      path: '/reservations',
      search: queryString.stringify(search),
    });
  };

  const onChangeRowsPerPage = (event) => {
    const search = { rowsPerPage: event.target.value };
    props.history.push({
      path: '/reservations',
      search: queryString.stringify(search),
    });
  };

  const unlock = async (token) => {
    const alert = confirm('ユーザー側画面でも受取完了となり開錠ができなくなりますがよろしいですか？');
    if(alert){
      try {
        await unlockMutation({
          variables: {
            token,
          },
        });
        reservationsRefetch();
        props.showAlert(
            "Successfully changed the reservation's status",
            'success',
        );
      } catch (error: any) {
        props.showAlert(error.message.replace('GraphQL error: ', ''), 'error');
      }
    }
  };

  const adminLock = async (token) => {
    try {
      await adminLockMutation({
        variables: {
          token,
        },
      });
      const alert = confirm('ドア状態がopenもしくはcloseか必ずご確認ください');
      if (alert) {
        reservationsRefetch();
        props.showAlert(
          "Successfully changed the reservation's status",
          'success',
        );
      }
    } catch (error: any) {
      props.showAlert(error.message.replace('GraphQL error: ', ''), 'error');
    }
  };
  const adminLockReservation = async (portId, doorId) => {
    const alert = confirm(
      'ユーザー側画面でも返却開始となりますがよろしいですか？',
    );
    if (alert) {
      await adminLockReservationMutation({
        variables: {
          portId,
          doorId,
        },
      });
      reservationsRefetch();
    } else {
    }
  };

  const updateReservation = async (reservationId, paymentStatus) => {
    const alert = confirm('支払い状況を変更してもよろしいですか？');
    if (alert) {
      await updateReservationMutation({
        variables: {
          reservationId,
          data: { paymentStatus },
        },
      });
      reservationsRefetch();
    }
  };

  const finish = async (reservationId) => {
    await finishMutation({
      variables: {
        reservationId,
      },
    });
    reservationsRefetch();
  };

  const createChat =  async (userId) => {
    return await createChatMutation({
      variables: {
        userId
      }
    })
  }

  const handleClose = () => {
    props.history.push('/reservations');
  };

  return (
    <ReservationsComponent1
      reservationsData={reservationsData}
      reservationsAllData={reservationsAllData}
      unlock={unlock}
      adminLock={adminLock}
      handleClose={handleClose}
      updateReservation={updateReservation}
      finish={finish}
      adminLockReservation={adminLockReservation}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      isSystemAdmin={props.isSystemAdmin}
      createChat={createChat}
    />
  );
};
