import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import People from '@material-ui/icons/People';
import Business from '@material-ui/icons/Business';
import List from '@material-ui/icons/List';
import ChildFriendly from '@material-ui/icons/ChildFriendly';

export const DashboardUI = ({
  adminDashboard,
  // choosePort,
  // ports,
  chosenPortId,
  chosenPort,
}: any): JSX.Element => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Grid container justifyContent="space-around">
        <Grid>
          <Card>
            <People />
            {adminDashboard.customersNumber} ユーザー
          </Card>
        </Grid>
        <Grid>
          <Card>
            <Business />
            {adminDashboard.portsNumber} ポート
          </Card>
        </Grid>
        <Grid>
          <Card>
            <List />
            {adminDashboard.reservationsNumber} 予約
          </Card>
        </Grid>
        <Grid>
          <Card>
            <ChildFriendly />
            {adminDashboard.babyCarsNumber} ベビーカー
          </Card>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around">
        {chosenPortId ? (
          <div>
            <Grid style={{ margin: '20px' }}>
              <Card>
                {chosenPort.numberOfDoors} ドア <br />
              </Card>
            </Grid>
            <Grid style={{ margin: '20px' }}>
              <Card>
                {chosenPort.numberOfAvailableBabyCars} 受取可能ベビーカー
                <br />
              </Card>
            </Grid>
            <Grid style={{ margin: '20px' }}>
              <Card>
                {chosenPort.numberOfAvailableDoors} 返却可能ドア
                <br />
              </Card>
            </Grid>
          </div>
        ) : null}
      </Grid>
    </div>
  );
};
