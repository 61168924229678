import {Button, DatePicker, Form, Select, Space} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from "moment";
import "moment/locale/ja";
import * as XLSX from "xlsx";
import gql from 'graphql-tag';
import {useQuery} from '@apollo/client';
import {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const dateFormat = 'YYYY-MM-DD';
export const ExportReservationsComponent = (props: any) => {
        const [loading, setLoading] = useState(false);
        const [showPreview, setShowPreview] = useState(false);
        const [activeDownload, setActiveDownload] = useState(false);
        const [dataPreview, setDataPreview] = useState([]);
        const [totalTaxedAmount, setTotalTaxAmount] = useState(0)
        const [form] = Form.useForm();

        const {
            data: portsData,
        } = useQuery<any>(comboboxPorts, {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        });

        const {
            refetch: getReservationData,
        } = useQuery<any>(exportReservations, {
            skip: true,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        });

        const downloadData = async () => {
            const formValue = form.getFieldsValue();
            const params = {
                ports: formValue.ports,
                startTime: moment(formValue.time[0]).format(dateFormat),
                endTime: moment(formValue.time[1]).format(dateFormat),
            }
            try {
                const {data, error} = await getReservationData({...params});
                if (!!error) {
                    props.showAlert(
                      "ダウンロードに失敗しました",
                      'warning',
                    );
                    return;
                }
                if (showPreview) {
                    let totalTaxedAmount = 0;
                    // let totalAmount = 0;
                    const dataPreview = data?.exportReservations.map((a: any, idx: number) => {
                        totalTaxedAmount += a?.taxedAmount;
                        // totalAmount += a?.taxedAmount / 1.1;
                        return {
                            id: idx + 1,
                            startPort: a?.startPort?.name ? a?.startPort?.name : '',
                            port: a?.port?.name ? a?.port?.name : '',
                            status: a?.status ? '返却済' : '貸出中',
                            amount: a?.taxedAmount / 1.1,
                            coupon: a?.issuedCoupon ? a?.issuedCoupon?.coupon?.name : '',
                            taxedAmount: a?.taxedAmount,
                            startDate: a?.startTime
                              ? `${moment(a.startTime).format('YYYY/MM/DD')}`
                              : '',
                            duration: getDuration(a?.startTime, a?.endTime),
                            product: a?.product?.name ? a?.product?.name : '',
                            discountPercent: a?.discountPercent,
                            commissionIncludedTax: +(a?.taxedAmount)*+(a?.discountPercent/100),
                            commissionExcludingTax: +(a?.taxedAmount / 1.1)*+(a?.discountPercent/100),

                        };
                    });
                    setTotalTaxAmount(totalTaxedAmount);
                    setDataPreview(dataPreview);
                    setActiveDownload(true)
                    setShowPreview(false)
                }
                if (activeDownload && !showPreview) {
                    const filename = `利用実績(${moment(formValue.time[0]).format("YYYY年MM月DD日")}~${moment(formValue.time[1]).format("YYYY年MM月DD日")})`
                    genXlsxFile(data, filename);
                }
            } catch (_) {
                props.showAlert(
                  "ダウンロードに失敗しました",
                  'warning',
                );
            }
        }

        const onReset = () => {
            form.resetFields();
            setShowPreview(false);
            setActiveDownload(false);
        }
        const formatNumberWithDots = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return (
            <div>
                <h3>予約一覧（報告用Excel出力）</h3>
                <div>
                    <Form form={form}
                          onFinish={async () => {
                              setLoading(true);
                              await downloadData();
                              setLoading(false);
                          }}
                          labelCol={{span: 4}}
                          wrapperCol={{span: 16}}
                          style={{maxWidth: 700}}>
                        <Form.Item
                            label="拠点"
                            name="ports"
                            rules={[{required: true, message: "※必須"}]}
                        >
                            <Select
                                mode='multiple'
                                allowClear
                                placeholder="拠点"
                                options={
                                    portsData?.ports.map(val => ({
                                        label: val.name,
                                        value: val.id,
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="期間"
                            name="time"
                            rules={[{required: true, message: "※必須"}]}
                        >
                            <DatePicker.RangePicker
                                locale={locale}
                                format={dateFormat}
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{offset: 4, span: 16}}>
                            <Space>
                                <Button htmlType="button" onClick={onReset}>
                                    クリア
                                </Button>
                                <Button htmlType="submit" type="primary" loading={loading} onClick={() => setShowPreview(true)}>
                                    出力
                                </Button>
                                <Button htmlType="submit" type="primary" loading={loading} disabled={!activeDownload}>
                                    ダウンロード
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
                {
                  activeDownload && <div style={{width: '100%', overflowX: "auto"}}>
                      <div style={{fontWeight: 600, marginBottom: '20px', textAlign: 'right'}}>
                          <span>合計売上（税込）：</span>
                          <span>{formatNumberWithDots(totalTaxedAmount.toFixed())}円</span>
                      </div>
                      <Table style={{ marginBottom: '50px'}}>
                          <TableHead style={{ backgroundColor: '#f0f2f5' }}>
                              <TableRow>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px' }}>ID</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>日付</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>ポート名（貸出）</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>ポート名（返却）</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>ステータス</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>利用時間</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>プラン</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>利用クーポン名</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>売上（税込）</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>売上（税抜）</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>手数料割合</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>手数料（税込）</TableCell>
                                  <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap", fontSize: '12px'  }}>手数料（税抜）</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  dataPreview && dataPreview.length > 0 && dataPreview.map((item: any, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.id}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.startDate}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.startPort}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.port}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.status}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.duration}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.product}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.coupon}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            ¥{formatNumberWithDots(item.taxedAmount.toFixed())}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            ¥{formatNumberWithDots(item.amount.toFixed())}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            {item.discountPercent}%
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            ¥{formatNumberWithDots(item.commissionIncludedTax.toFixed())}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", fontSize: '12px'  }}>
                                            ¥{formatNumberWithDots(item.commissionExcludingTax.toFixed())}
                                        </TableCell>
                                    </TableRow>
                                ))
                              }
                          </TableBody>
                      </Table>
                  </div>
                }
            </div>
        );
  }
;

const initHeaders = [
    {label: 'ID', key: 'id'},
    {label: '日付', key: 'startDate'},
    {label: 'ポート名（貸出）', key: 'startPort'},
    {label: 'ポート名（返却）', key: 'port'},
    {label: 'ステータス', key: 'status'},
    {label: '利用時間', key: 'duration'},
    {label: 'プラン', key: 'product'},
    {label: '利用クーポン名', key: 'coupon'},
    {label: '売上（税込）', key: 'taxedAmount'},
    {label: '売上（税抜）', key: 'amount'},
    {label: '手数料割合', key: 'discountPercent'},
    {label: '手数料（税込）', key: 'commissionIncludedTax'},
    {label: '手数料（税抜）', key: 'commissionExcludingTax'},
];

const genXlsxFile = (reservationData, filename) => {
    let totalTaxedAmount = 0;
    let totalAmount = 0;
    const data = reservationData?.exportReservations.map((a: any, idx: number) => {
        totalTaxedAmount += a?.taxedAmount;
        totalAmount += a?.taxedAmount / 1.1;
        return {
            id: idx + 1,
            startPort: a?.startPort?.name ? a?.startPort?.name : '',
            port: a?.port?.name ? a?.port?.name : '',
            status: a?.status ? '返却済' : '貸出中',
            amount: a?.taxedAmount / 1.1,
            coupon: a?.issuedCoupon ? a?.issuedCoupon?.coupon?.name : '',
            taxedAmount: a?.taxedAmount,
            startDate: a?.startTime
                ? `${moment(a.startTime).format('YYYY/MM/DD')}`
                : '',
            duration: getDuration(a?.startTime, a?.endTime),
            product: a?.product?.name ? a?.product?.name : '',
            discountPercent: a?.discountPercent + '%',
            commissionIncludedTax: +(a?.taxedAmount)*+(a?.discountPercent/100),
            commissionExcludingTax: +(a?.taxedAmount / 1.1)*+(a?.discountPercent/100),
        };
    });
    const header = [initHeaders.map(value => value.label)];
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, header);
    XLSX.utils.sheet_add_json(worksheet, data,
        {
            origin: 'A2',
            skipHeader: true,
            header: initHeaders.map(value => value.key)
        });

    const footer = [
        [],
        [],
        ['', '', '', '', '', '', '', '', '税込', '税抜'],
        ['合計', '', '', '', '', '', '', '', totalTaxedAmount, totalAmount]];

    XLSX.utils.sheet_add_aoa(worksheet, footer, {
        origin: `A${2 + data.length}`
    });

    /* new format */
    const fmt = "[$¥-411]#,##0";
    /* change cell format of range */
    const range = {s: {r: header.length, c: 8}, e: {r: data.length + header.length + footer.length, c: 12},  };
    for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            let cell = worksheet[XLSX.utils.encode_cell({r: R, c: C})];
            if (!cell || cell.t != 'n') continue; // only format numeric cells
            cell.z = fmt;
        }
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    /* Export to file (start a download) */
    XLSX.writeFile(workbook, `${filename}.xlsx`);
}

const getDuration = (startTime: any, endTime: any): string => {
    if (!startTime && !endTime) {
        return '';
    }

    const value = moment.duration(moment(endTime).diff(moment(startTime))).asMinutes();
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

const comboboxPorts = gql`
    query comboboxPorts {
        ports {
            id
            name
            address
            zipCode
        }
    }
`;

const exportReservations = gql`
    query exportReservations($ports: [String!]!, $startTime: DateTime!, $endTime: DateTime!) {
        exportReservations(ports: $ports, startTime: $startTime, endTime: $endTime) {
            id
            startTime
            endTime
            returnTime
            token
            status
            paymentStatus
            user {
                id
                firstname
                lastname
                phoneNo
                deletedAt
            }
            startPort {
                id
                name
            }
            port {
                id
                name
            }
            door {
                id
                doorIndex
                status
            }
            babyCar {
                id
                code
                status
            }
            taxedAmount
            issuedCoupon {
                id
                createdAt
                coupon {
                    id
                    discountAmount
                    name
                }
            }
            startDoor {
                id
                doorIndex
                status
            }
            product {
                name
            }
            extendedTimes
            discountPercent
        }
    }
`;
