import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MySnackBar } from '@src/components/MySnackBar';
import { ProductsComponent1 } from '@src/components/Products/component';

class Products extends React.Component<any, any> {
  state: any = {
    isModelOpen: false,
    editProductId: null,
    name: '',
    nameEn: '',
    code: '',
    description: '',
    price: '',
    duration: '',
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => {
    this.setState({
      isModelOpen: false,
      editProductId: null,
      name: '',
      nameEn: '',
      code: '',
      description: '',
      price: '',
      duration: '',
    });
  };

  addProduct = async () => {
    await this.props.addProduct({
      variables: {
        data: {
          name: this.state.name,
          code: this.state.code,
          nameEn: this.state.nameEn,
          description: this.state.description,
          price: parseInt(this.state.price, 10),
          duration: parseInt(this.state.duration, 10),
        },
      },
    });
    await this.props.productsQuery.refetch();
    this.setState((prevState) => ({
      isModelOpen: !prevState.isModelOpen,
    }));
  };

  setUpdateProduct = (product) => {
    const { isModelOpen } = this.state;
    this.setState({
      isModelOpen: !isModelOpen,
      editProductId: product.id,
      name: product.name,
      code: product.code,
      price: product.price,
      nameEn: product.nameEn,
      duration: product.duration,
      description: product.description,
    });
  };

  updateProduct = async () => {
    await this.props.updateProduct({
      variables: {
        productId: this.state.editProductId,
        data: {
          name: this.state.name,
          code: this.state.code,
          nameEn: this.state.nameEn,
          description: this.state.description,
          price: parseInt(this.state.price, 10),
          duration: parseInt(this.state.duration, 10),
        },
      },
    });
    await this.props.productsQuery.refetch();
    this.setState((prevState) => ({
      isModelOpen: !prevState.isModelOpen,
    }));
  };

  handleOpen = () => {
    this.setState({
      isModelOpen: true,
    });
  };

  render() {
    const {
      productsQuery: { products, loading, error },
      isSystemAdmin,
    } = this.props;
    if (loading) return <CircularProgress />;
    if (error)
      return (
        <MySnackBar variant="error" message={error.graphQLErrors[0].message} />
      );
    return (
      <ProductsComponent1
        onChangeHandler={this.onChangeHandler}
        handleClose={this.handleClose}
        handleOpen={this.handleOpen}
        addProduct={this.addProduct}
        updateProduct={this.updateProduct}
        setUpdateProduct={this.setUpdateProduct}
        state={this.state}
        isSystemAdmin={isSystemAdmin}
        products={products}
      />
    );
  }
}

const productsQuery = gql`
  query productsQuery {
    products {
      id
      name
      nameEn
      code
      description
      price
      duration
    }
  }
`;
const addProduct = gql`
  mutation ADD_PRODUCT($data: AddProductInput!) {
    addProduct(data: $data) {
      id
    }
  }
`;
const updateProduct = gql`
  mutation updateProduct($productId: ID!, $data: ProductUpdateInput) {
    updateProduct(productId: $productId, data: $data) {
      id
    }
  }
`;

export const ProductsComponent = compose(
  graphql(updateProduct, { name: `updateProduct` }),
  graphql(addProduct, { name: `addProduct` }),
  graphql(productsQuery, {
    name: 'productsQuery',
    options: { fetchPolicy: 'network-only' },
  }),
)(Products);
