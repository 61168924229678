import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export const DeleteDoorModal = ({
  doorId,
  deleteDoor,
  deleteDoorCancel,
}: any) => (
  <Dialog
    open={!!doorId}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">
      ドアを削除してよろしいですか?
    </DialogTitle>
    <DialogActions>
      <Button
        onClick={async () => deleteDoor(doorId)}
        color="primary"
        autoFocus>
        Yes
      </Button>
      <Button onClick={() => deleteDoorCancel()} color="primary">
        No
      </Button>
    </DialogActions>
  </Dialog>
);
