import { graphql } from 'react-apollo';
import { gql } from 'apollo-boost';
import React from 'react';
import { DashboardUI } from '@src/components/Dashboard/DashboardUI';

class Dashboard extends React.Component<any, any> {
  state: any = {
    chosenPort: null,
    chosenPortId: null,
  };

  choosePort = (port) => {
    this.setState({
      chosenPort: port,
      chosenPortId: port.id,
    });
  };

  render() {
    if (this.props.dashboard.loading) return <div>Loading...</div>;
    const {
      dashboard: { adminDashboard, ports },
    } = this.props;
    return (
      <DashboardUI
        adminDashboard={adminDashboard}
        choosePort={this.choosePort}
        ports={ports}
        chosenPortId={this.state.chosenPortId}
        chosenPort={this.state.chosenPort}
      />
    );
  }
}

const dashboardQuery = gql`
  query DASHBOARD_QUERY {
    adminDashboard {
      customersNumber
      portsNumber
      reservationsNumber
      babyCarsNumber
    }
    ports {
      id
      name
      longitude
      latitude
      imageUrls
      numberOfDoors
      numberOfAvailableDoors
      numberOfAvailableBabyCars
    }
  }
`;

export const DashBoardComponent = graphql(dashboardQuery, {
  name: 'dashboard',
  options: {
    fetchPolicy: 'network-only',
  },
})(Dashboard);
