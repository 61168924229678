import decode from 'jwt-decode';
import moment from 'moment';

export function getTokenExpirationDate(token) {
  const decoded: any = decode(token);
  if (!decoded.exp) {
    return null;
  }
  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export const dateDisplay = (latestMessageTime) => {
  if (!latestMessageTime) {
    return null;
  }
  const now = moment();
  if (moment(now).isSame(latestMessageTime, 'day')) {
    latestMessageTime = moment(latestMessageTime).format('h:mm a');
    return latestMessageTime;
  }
  latestMessageTime = moment(latestMessageTime).format('YYYY/MM/DD');
  return latestMessageTime;
};
