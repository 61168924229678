import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const DoorModal = ({
  editDoorId,
  doorCode,
  onChangeHandler,
  updateDoor,
  updateDoorCancel,
}: any) => (
  <Dialog
    open={!!editDoorId}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
    <DialogTitle id="confirmation-dialog-title">ドア編集</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <TextField
          style={{ width: '100%', alignSelf: 'center' }}
          value={doorCode}
          onChange={onChangeHandler}
          name="doorCode"
          label="ドア番号"
          margin="dense"
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => updateDoor()} color="primary">
        Apply
      </Button>
      <Button onClick={() => updateDoorCancel()} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);
