import { ApolloQueryResult } from '@apollo/client';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { HelpQuestionContents } from '@src/contents/helpQuestion';
import {
  HelpQuestion,
  SEARCHMESSAGE,
  SEARCHMESSAGEVariables,
  SEARCHPUBLICMESSAGES,
} from '@src/generated/graphql';
import {makeStyles} from "@material-ui/core/styles";
import useWindowDimensions from "@src/hooks/useWindowDimensions";
const useStyles = makeStyles(() => ({
  root: {
    marginTop: '3px',

    '& .MuiInputBase-input': {
      padding: '6px 12px',
      backgroundColor: '#FFF',
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    },
  },
}));

type propsType = {
  setSelect: React.Dispatch<React.SetStateAction<string>>;
  searchRefetch: (
    variables: Partial<SEARCHMESSAGEVariables> | undefined,
  ) =>
    | Promise<ApolloQueryResult<SEARCHMESSAGE>>
    | Promise<ApolloQueryResult<SEARCHPUBLICMESSAGES>>;
  inputKeywordEl: any;
  inputHelpSelectQuestionsEl: any;
};

export const SearchTextField = ({
  setSelect,
  searchRefetch,
  inputKeywordEl,
  inputHelpSelectQuestionsEl,
}: propsType) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const onPressClear = () => {
    setSelect('');
    if (inputKeywordEl.current.value) {
      inputKeywordEl.current.value = '';
    } else if (inputHelpSelectQuestionsEl.current.value) {
      inputHelpSelectQuestionsEl.current.value = '';
    }
  };
  const onPress = () => {
    if (inputKeywordEl.current.value) {
      setSelect(inputKeywordEl.current.value);
      searchRefetch({
        text: inputKeywordEl.current.value || '',
      });
    } else if (inputHelpSelectQuestionsEl.current.value) {
      setSelect(inputHelpSelectQuestionsEl.current.value);
      searchRefetch({
        text: inputHelpSelectQuestionsEl.current.value || '',
      });
    }
  };

  return (
    //部分一致検索！
    <>
      <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
        <TextField
          inputRef={inputKeywordEl}
          className={classes.root}
          id="field"
          color="secondary"
          variant="outlined"
          label=""
          style={{
            width: width < 768 ? '100%' : 'max-content',
          }}
        />
        <div style={{
          display: 'flex',
          flexDirection: width < 768 ? 'column' : 'row',
          width: width < 768 ? '100%' : 'max-content',
          gap: '10px',
          marginTop: '-1px'
        }}>
          <button
            style={{
              width: width < 768 ? '100%' : 'max-content',
              fontSize: '15px',
              border: 'none',
              borderRadius: '4px',
              backgroundColor: '#fa4e69',
              color: '#fff',
              padding: '4px 14px',
              cursor: 'pointer',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
            }}
            onClick={() => onPress()}>
            検索
          </button>
          <button
            style={{
              fontSize: '15px',
              border: 'none',
              borderRadius: '4px',
              color: '#374151',
              padding: '4px 14px',
              backgroundColor: '#fff',
              cursor: 'pointer',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
            }}
            onClick={() => onPressClear()}>
            クリア
          </button>
        </div>
      </div>
      <div style={{
        marginTop: '15px',
      }}>
        <FormControl
          fullWidth
          style={{
            marginBottom: '10px',
          }}>
          <InputLabel id="demo-simple-select-label">質問検索</InputLabel>
          <Select
            inputRef={inputHelpSelectQuestionsEl}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="SelectQuestion">
            <MenuItem value={HelpQuestionContents[HelpQuestion.BABYCAR_BROKE]}>
              {HelpQuestionContents[HelpQuestion.BABYCAR_BROKE]}
            </MenuItem>
            <MenuItem value={HelpQuestionContents[HelpQuestion.BOX_TROUBLE]}>
              {HelpQuestionContents[HelpQuestion.BOX_TROUBLE]}
            </MenuItem>
            <MenuItem value={HelpQuestionContents[HelpQuestion.LOST_BABYCAR]}>
            {HelpQuestionContents[HelpQuestion.LOST_BABYCAR]}
            </MenuItem>
            <MenuItem value={HelpQuestionContents[HelpQuestion.OHTER]}>
              {HelpQuestionContents[HelpQuestion.OHTER]}
            </MenuItem>
            <MenuItem
              value={HelpQuestionContents[HelpQuestion.SETTLEMENT_TROUBLE]}>
              {HelpQuestionContents[HelpQuestion.SETTLEMENT_TROUBLE]}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
};
