import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useState} from 'react';
import Typography from "@material-ui/core/Typography";

export const ReservationButton = ({
  reservation = { babyCar: {} },
  unlock,
  adminLock,
}: any) => {
  if (reservation.status) return null;
  if (!reservation.returnTime && reservation.babyCar.status === 'INSIDE') {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => unlock(reservation.token)}>
        受取完了
      </Button>
    );
  }
  if (reservation.returnTime && reservation.babyCar.status === 'OUTSIDE') {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => adminLock(reservation.token)}>
        返却完了
      </Button>
    );
  }
  return null;
};

export const ForcedUnlockButton = ({
  reservation = { babyCar: {} },
  adminLockReservation,
}: any) => {
  if (
    !reservation.status &&
    reservation.babyCar.status === 'OUTSIDE' &&
    reservation.returnTime === null
  ) {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          adminLockReservation(reservation.port.id, reservation.door.id)
        }>
        返却開始
      </Button>
    );
  }
  return null;
};

export const PaymentStatusButton = ({
  paidFailMsg,
  reservation,
  updateReservation,
}: any) => {
  const [checkPayment, setCheckPayment] = useState(reservation.paymentStatus);
  const mutationPaymentStatus = async () => {
    await updateReservation(reservation.id, !checkPayment);
  };

  return (
    <FormControlLabel
      value="top"
      style={{ alignItems: 'start' }}
      control={
        <Switch
          checked={checkPayment}
          onChange={async () => {
            setCheckPayment(!checkPayment);
            await mutationPaymentStatus();
          }}
          name="paymentStatus"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      }
      label={<div style={{fontSize:'12px',maxWidth: '200px'}}>
          <Typography
              style={{
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
              }}>
              {paidFailMsg !== '' ? paidFailMsg : (checkPayment ? '支払済' : '未払い')}
          </Typography>
    </div>}
      labelPlacement="top"
    />
  );
};
