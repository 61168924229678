import {Button, Table} from 'antd';
import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {TextField} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Search as SearchIcon} from '@material-ui/icons';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100vh'
    },
    toolbar: {
        display: 'flex'
    },
    contentPanel: {
        flex: '1 1 100%',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        maxWidth: 300
    },
    iconButton: {
        padding: 10,
    }
}));

export type CustomerRowUserType = {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    phoneNo: string;
    creditCard?: Record<string, string> | null;
};
export type CustomerListType = {
    users: CustomerRowUserType[];
    resendVerifiedEmail: (email:string)=>void;
};
export const CustomersComponent = ({
                                       users = [],
                                       resendVerifiedEmail
                                   }: CustomerListType): JSX.Element => {
    const classes = useStyles()
    const [searchParams, setSearchParams] = useState<any>({
        name: '',
        email: '',
        phone: '',
    });
    const location = useLocation();
    const name = location?.state?.params;

    useEffect(() => {
        if (name) {
            setSearchParams({
                ...searchParams,
                name: name
            })
        }
    }, [name]);

    const handleChange = (event) => {
        const {target} = event;
        searchParams[target.name] = target.value;
    }

    const handleSearch = (event) => {
        event.preventDefault();
        setSearchParams({...searchParams});
    }

    const filterUser = (users: CustomerRowUserType[] = []) => {
        console.log(users);
        console.log(searchParams);
        return users.filter(val =>
            `${val.firstname || ''}${val.lastname || ''}`?.includes(searchParams.name) &&
            val.email?.includes(searchParams.email) &&
            val.phoneNo?.includes(searchParams.phone)
        )
    }

    const columns = [
        {
            title: '名前',
            key: 'firstname',
            render: (_, user) => {
                return <div>{`${user.firstname || ''}${user.lastname || ''}`}</div>;
            },
        },
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '電話番号',
            dataIndex: 'phoneNo',
            key: 'phoneNo',
        },
        {
            title: 'クレジットカード登録状態',
            dataIndex: 'creditCard',
            key: 'creditCard',
            render: (creditCard) => {
                return creditCard ? '登録済' : '未登録';
            },
        },{
            title: '開封状態 ',
            // dataIndex: 'creditCard',
            key: 'openedCondition',
            render: (record:any) => {
                return !record.emailConfirmationNumber ? '確認済み' : '検証されていない';
            },
        },{
            title: ' ',
            key: 'action',
            render: (record:any) => {
                return <Button
                    type="primary"
                    disabled={!record.emailConfirmationNumber}
                    onClick={() =>resendVerifiedEmail(record.email)}>
                    再送
                </Button>;
            },
        },
    ];
    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <div className={classes.contentPanel}>
                    <form className={classes.form} onSubmit={handleSearch}>
                        <FormControl className={classes.input}>
                            <TextField name='name'
                                       label='名前'
                                       variant='outlined'
                                       defaultValue={name ? name : ''}
                                       InputLabelProps={{shrink: true}}
                                       onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl className={classes.input}>
                            <TextField name='email'
                                       label='メールアドレス'
                                       variant='outlined'
                                       InputLabelProps={{shrink: true}}
                                       onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl className={classes.input}>
                            <TextField name='phone'
                                       label='電話番号'
                                       variant='outlined'
                                       InputLabelProps={{shrink: true}}
                                       onChange={handleChange}
                            />
                        </FormControl>
                        <IconButton type='submit' className={classes.iconButton} aria-label="search">
                            <SearchIcon/>
                        </IconButton>
                    </form>
                </div>
            </div>
            <Table rowKey={(record) => record.id} dataSource={filterUser(users)} columns={columns}/>
        </div>
    );
};
// export const CustomersComponent1 = ({
//   users = [],
// }: CustomerListType): JSX.Element => (
//   <div style={{ width: '100%', height: '100%' }}>
//     <h1>ユーザー</h1>
//     <Paper elevation={2}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>名前</TableCell>
//             <TableCell>メールアドレス</TableCell>
//             <TableCell>電話番号</TableCell>
//             <TableCell>クレジットカード登録状態</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {users.map((user) => (
//             <CustomerRow user={user} key={user.id} />
//           ))}
//         </TableBody>
//       </Table>
//     </Paper>
//   </div>
// );
