import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

export const ProductRow = ({
  product,
  isSystemAdmin,
  setUpdateProduct,
}: any) => (
  <TableRow>
    <TableCell>{product.name}</TableCell>
    <TableCell>{product.nameEn}</TableCell>
    <TableCell>{product.code}</TableCell>
    <TableCell>{product.description}</TableCell>
    <TableCell>{product.price}</TableCell>
    <TableCell>{product.duration}</TableCell>
    {isSystemAdmin ? (
      <TableCell>
          <Button
              variant="outlined"
              color="primary"
              onClick={() => setUpdateProduct(product)}>
              <Icon color="primary">edit</Icon>
          </Button>
      </TableCell>
    ) : null}
  </TableRow>
);
