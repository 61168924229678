import { gql, NetworkStatus, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import { SinglePublicChatComponent } from '@src/components/PublicChats/SingleChat';
import {
  CHAT_QUERY3,
  CHAT_QUERY3_publicChats,
  SEARCHPUBLICMESSAGES,
  SEARCHPUBLICMESSAGESVariables,
  SEARCHPUBLICMESSAGES_searchPublicMessages,
} from '@src/generated/graphql';
import { dateDisplay } from '@src/utils';
import { SearchTextField } from '@src/utils/SearchTextField';
import moment from 'moment';
import {DisconnectOutlined, LeftOutlined} from "@ant-design/icons";
import Tooltip from "@material-ui/core/Tooltip";
import useWindowDimensions from "@src/hooks/useWindowDimensions";
import {withStyles} from "@material-ui/core/styles";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  customerList: {
    // overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ccc',
    borderTop: 'none',
    width: '27vw',
  },
  public: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: '1px',
    borderColor: '#ccc',
    borderRightStyle: 'solid',
    borderLeft: 'none',
    borderBottomStyle: 'solid',
    width: '73vw',
  },
  chat: {
    // overflow: 'auto',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-end',
    // flexGrow: 1,
  },
  date: {
    color: '#ccc',
    fontSize: '0.7em',
  },
  noChat: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    fontSize: '16px',
    color: '#9ca3af',
    backgroundColor: '#fff'
  }
};

const CHAT_QUERY = gql`
  query CHAT_QUERY3 {
    publicChats {
      id
      email
      lastSeenByAdmin
      lastSeenByUser
      latestMessageTime
      createdAt
      messages {
        createdAt
        id
        imageContent {
          imageKey
          imageBucket
        }
        isAdminMessage
        publicChat {
          id
          lastSeenByAdmin
        }
        text
      }
    }
  }
`;
const CHAT_SUBSCRIPTION = gql`
  subscription CHAT_SUBSCRIPTION3 {
    publicChatsSubscription {
      id
      createdAt
      text
      isAdminMessage
      publicChat {
        id
        email
        lastSeenByAdmin
        lastSeenByUser
        latestMessageTime
      }
    }
  }
`;
const SEND_MESSAGE = gql`
  mutation SEND_MESSAGE3(
    $email: String!
    $text: String
    $messageType: MessageType!
    $imageKey: String
    $imageBucket: String
  ) {
    sendPublicMessage(
      email: $email
      text: $text
      isAdminMessage: true
      messageType: $messageType
      imageKey: $imageKey
      imageBucket: $imageBucket
    ) {
      id
    }
  }
`;

const SEEN_BY_ADMIN_PUBLIC_CHAT = gql`
  mutation SEEN_BY_ADMIN_PUBLIC_CHAT($publicChatId: ID!) {
    seenByAdminPublicChat(publicChatId: $publicChatId) {
      id
    }
  }
`;

const SEARCH_PUBLIC_MESSAGES_QUERY = gql`
  query SEARCHPUBLICMESSAGES($text: String, $helpQuestion: HelpQuestion) {
    searchPublicMessages(text: $text, helpQuestion: $helpQuestion) {
      id
      email
      lastSeenByAdmin
      lastSeenByUser
      latestMessageTime
      createdAt
      messages {
        createdAt
        id
        imageContent {
          imageKey
          imageBucket
        }
        isAdminMessage
        publicChat {
          id
          lastSeenByAdmin
        }
        text
      }
    }
  }
`;

export const PublicChat = (): JSX.Element => {
  const [select, setSelect] = useState<string>('');
  const inputKeywordEl = useRef(null);
  const inputHelpSelectQuestionsEl = useRef(null);
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [setSeenByAdminPublicChat] = useMutation(SEEN_BY_ADMIN_PUBLIC_CHAT);
  const [receiveMessage, setReceiveMesssage] = useState<any>();
  const [chatId, setChatId] = useState<string | null>(null);
  const [visible, setVisible] = useState(true);
  const [activeMessage, setActiveMessage] = useState<string | undefined>('');
  const { width } = useWindowDimensions();
  const {
    error: publicChatError,
    data: publicChatData,
    refetch: publicChatDataRefetch,
    subscribeToMore: publicChatSubscribeToMore,
  } = useQuery<CHAT_QUERY3>(CHAT_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: publicSearchData,
    refetch: publicSearchRefetch,
    networkStatus: publicSearchNetworkStatus,
  } = useQuery<SEARCHPUBLICMESSAGES, SEARCHPUBLICMESSAGESVariables>(
    SEARCH_PUBLIC_MESSAGES_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    const subscribeChatFunc = () => {
      try {
        publicChatSubscribeToMore({
          document: CHAT_SUBSCRIPTION,

          updateQuery: ((prev: any, { subscriptionData }: any) => {
            if (!subscriptionData.data) return prev;
            publicChatDataRefetch();
            publicSearchRefetch();
            return Object.assign({}, prev, {});
          }) as any,
        });
      } catch (e) {
        console.error('subscription error', e);
      }
    };
    subscribeChatFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicChatSubscribeToMore]);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: '1rem',
    },
  }))(Tooltip);

  const RenderSingleChat = ({ email, messages, sendMessage }: any) => {
    if (email)
      return (
        <SinglePublicChatComponent
          messages={messages}
          email={email}
          sendMessage={sendMessage}
          receiveMessage={receiveMessage}
        />
      );
    return <div style={styles.noChat}>
      <DisconnectOutlined style={{fontSize: '80px'}}/>
      No chat is selected
    </div>;
  };


  const allMember = () => {
    return (
      <>
        <span>
          {visible}
        </span>
        <button
          style={{
            fontSize: '15px',
            marginLeft: width < 1130 ? '0' : '15px',
            marginTop: width < 1130 ? '10px' : '0',
            border: 'none',
            borderRadius: '4px',
            color: '#374151',
            padding: '4px 14px',
            backgroundColor: '#fff',
            cursor: 'pointer',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'}}
          onClick={() => setVisible((visible) => !visible)}>
          切替
        </button>
      </>
    );
  };
  const filteredHaveChatMessage = publicChatData?.publicChats?.filter(
    (msg) => msg.messages?.length !== 0,
  );
  const chatsListItemElements = (
    publicChat:
      | CHAT_QUERY3_publicChats
      | SEARCHPUBLICMESSAGES_searchPublicMessages
      | null,
  ) => {
    return (
      <ListItem
        style={{
          position: 'relative',
          marginBottom: '10px',
          padding: '16px',
          backgroundColor: '#FFF',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          overflow: 'hidden',
        }}
        button
        key={publicChat?.id}
        onClick={async () => {
          const messageValueFn = () => {
            const messageValue = localStorage.getItem(
              `${publicChat?.id}_messageKey`,
            )
              ? localStorage.getItem(`${publicChat?.id}_messageKey`)
              : '';
            return messageValue;
          };
          setReceiveMesssage(messageValueFn());
          await setSeenByAdminPublicChat({
            variables: {
              publicChatId: publicChat?.id,
            },
          });
          publicChatDataRefetch();
          setChatId(publicChat?.id || '');
          setActiveMessage(publicChat?.id);
        }}>
        <ListItemAvatar style={{
          maxWidth: '50px',
          width: '100%',
          display: 'flex'
        }}>
          <Avatar>{publicChat?.email.substring(0, 1)}</Avatar>
        </ListItemAvatar>
        <div style={{
          flex: 1,
          position: 'relative',
          width: 'calc(100% - 50px)',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Badge style={{
            position: 'relative',
            width: '100%'
          }}
            color="secondary"
            badgeContent={
              chatId === publicChat?.id
                ? 0
                : publicChat?.messages?.filter(
                  (message) => message?.createdAt > publicChat?.lastSeenByAdmin,
                ).length
            }>
            <ListItemText primary={
              <LightTooltip title={publicChat?.email ? publicChat?.email : ''}
                            placement="bottom">
                <Typography
                  noWrap
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    display: 'block'
                  }}>
                  {publicChat?.email}
                </Typography>
              </LightTooltip>
            }/>
          </Badge>
          <p style={styles.date}>
            {dateDisplay(publicChat?.latestMessageTime)}
          </p>
        </div>
        {
          activeMessage && activeMessage === publicChat?.id ?
            <div style={{ position: 'absolute', backgroundColor: '#fa4e69', right: 0, width: '5px', height: '100%' }}></div>
            : <></>
        }
      </ListItem>
    );
  };

  const selectChatListItem = () => {
    if (
      select &&
      publicSearchData?.searchPublicMessages &&
      publicSearchData?.searchPublicMessages?.length > 0
    ) {
      return (
        <List style={{overflow: 'auto', padding: '0 10px', backgroundColor: '#f4f4fa'}} >
          {publicSearchData?.searchPublicMessages.map(
            (chat: SEARCHPUBLICMESSAGES_searchPublicMessages | null) =>
              chatsListItemElements(chat),
          )}
        </List>
      );
    } else if (
      select &&
      publicSearchData?.searchPublicMessages &&
      publicSearchData?.searchPublicMessages?.length === 0
    ) {
      return (
        <div style={{ marginTop: '10px', marginLeft: '10px' }}>
          検索結果は0件でした。
        </div>
      );
    } else if (
      !visible &&
      !select &&
      filteredHaveChatMessage &&
      filteredHaveChatMessage.length > 0
    ) {
      const sortedHaveChatMessage = filteredHaveChatMessage
        ?.filter((a) => a.latestMessageTime)
        .sort((a, b) =>
          moment(b?.latestMessageTime).diff(moment(a?.latestMessageTime)),
        );
      return (
        <List style={{overflowY: 'auto', padding: '0 10px', backgroundColor: '#f4f4fa'}} >
          {sortedHaveChatMessage.map(
            (chat: SEARCHPUBLICMESSAGES_searchPublicMessages) =>
              chatsListItemElements(chat),
          )}
        </List>
      );
    } else if (
      visible &&
      !select &&
      publicChatData?.publicChats &&
      publicChatData?.publicChats?.length > 0
    ) {
      let allPublicChatMessage: any = [];
      const allchatMessageCreatedAtSorted = publicChatData?.publicChats
        ?.filter((a) => a.createdAt)
        .sort((a, b) => moment(b?.createdAt).diff(moment(a?.createdAt)));
      const haveLatestMessageTimeChatMessage = allchatMessageCreatedAtSorted
        ?.filter((a) => a.latestMessageTime)
        .sort((a, b) =>
          moment(b?.latestMessageTime).diff(moment(a?.latestMessageTime)),
        );
      const noLatestMessageTimeChatMessage =
        allchatMessageCreatedAtSorted?.filter((a) => !a.latestMessageTime);
      if (haveLatestMessageTimeChatMessage && noLatestMessageTimeChatMessage) {
        const allchatMessageFirst = haveLatestMessageTimeChatMessage?.concat(
          noLatestMessageTimeChatMessage,
        );
        allPublicChatMessage.push(...allchatMessageFirst);
      } else {
        allPublicChatMessage.push(...publicChatData?.publicChats);
      }
      return (
        <List style={{overflow: 'auto', padding: '0 10px', backgroundColor: '#f4f4fa'}} >
          {allPublicChatMessage?.map(
            (chat: SEARCHPUBLICMESSAGES_searchPublicMessages) =>
              chatsListItemElements(chat),
          )}
        </List>
      );
    }
    return (
      <List>
        {publicChatData?.publicChats?.map(
          (chat: SEARCHPUBLICMESSAGES_searchPublicMessages) =>
            chatsListItemElements(chat),
        )}
      </List>
    );
  };
  const loadingTiming =
    publicSearchNetworkStatus === NetworkStatus.setVariables;
  if (!publicChatData) return <div>Loading...</div>;
  if (publicChatError) return <div>`Error! ${publicChatError.message}`</div>;

  if (width < 678 && activeMessage === '') {
    return (
      <div style={styles.container}>
        <div style={{
          height: 'calc(100vh - 126px)',
          // overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          border: 'none',
          width: '100%',
        }}>
          <AppBar position="static" color="default"
                  style={{boxShadow: 'none', borderTop: '1px solid #cccccc', backgroundColor: '#f4f4fa', zIndex: '10'}}>
            <Toolbar style={{padding: '16px'}}>
              <Typography variant="h6" color="inherit" style={{ width: '100%' }}>
                <div style={{fontSize: '14px', display: 'flex', flexDirection: width < 1130 ? 'column' : 'row'}}>
                  {visible ? '全ユーザー' : 'メッセージユーザー'}
                  {allMember()}
                </div>
                <div style={{marginTop: '15px'}}>
                  <SearchTextField
                    setSelect={setSelect}
                    searchRefetch={publicSearchRefetch}
                    inputKeywordEl={inputKeywordEl}
                    inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
                  />
                </div>
              </Typography>
            </Toolbar>
          </AppBar>
          {loadingTiming ? (
            <div style={{marginTop: '10px', marginLeft: '10px'}}>検索中</div>
          ) : (
            selectChatListItem()
          )}
        </div>
      </div>
    )
  } else if (width < 678 && activeMessage !== '') {
    return (
      <div style={styles.container}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          border: 'none',
          height: 'calc(100vh - 126px)',
          width: '100%',
        }}>
          <AppBar position="static" color="default" style={{ boxShadow: 'none', borderTop: '1px solid #cccccc' ,backgroundColor: '#f4f4fa', borderBottom: '1px solid #cccccc', flex: "0 0 auto"}}>
            <Toolbar style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              flexDirection: 'row'
            }}>
              <button style={{
                width: '36px',
                border: 'none',
                height: '36px',
                marginRight: '10px',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
                borderRadius: '4px',
                color: '#374151',
                backgroundColor: '#e5e7eb',
              }} onClick={() => setActiveMessage('')}>
                <LeftOutlined style={{fontSize: '16px'}}/>
              </button>
              <Typography variant="h6" color="inherit" style={{fontSize: '16px', fontWeight: '600'}}>
                {chatId !== null
                  ? publicChatData.publicChats.find((p) => p.id === chatId)?.email
                  : 'パブリックチャット'}
              </Typography>
            </Toolbar>
          </AppBar>
          <RenderSingleChat
            sendMessage={sendMessage}
            email={
              publicChatData.publicChats.find((p) => p.id === chatId)?.email
            }
            messages={
              publicChatData.publicChats.find((p) => p.id === chatId)?.messages
            }
          />
        </div>
      </div>
    )
  } else {
    return (
      <div style={styles.container}>
        <div style={{...styles.customerList, height: 'calc(100vh - 174px)',}}>
          <AppBar position="static" color="default"
                  style={{boxShadow: 'none', borderTop: '1px solid #cccccc', backgroundColor: '#f4f4fa', zIndex: '10' }}>
            <Toolbar style={{ padding: '16px' }}>
              <Typography variant="h6" color="inherit" style={{ width: '100%' }}>
                <div style={{fontSize: '14px', display: 'flex', flexDirection: width < 1130 ? 'column' : 'row'}}>
                  {visible ? '全ユーザー' : 'メッセージユーザー'}
                  {allMember()}
                </div>
                <div style={{marginTop: '10px'}}>
                  <SearchTextField
                    setSelect={setSelect}
                    searchRefetch={publicSearchRefetch}
                    inputKeywordEl={inputKeywordEl}
                    inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
                  />
                </div>
              </Typography>
            </Toolbar>
          </AppBar>
          {loadingTiming ? (
            <div style={{ marginTop: '10px', marginLeft: '10px' }}>検索中</div>
          ) : (
            selectChatListItem()
          )}
        </div>
        <div style={{...styles.public, height: 'calc(100vh - 174px)',}}>
          <AppBar position="static" color="default" style={{ boxShadow: 'none', borderTop: '1px solid #cccccc' ,backgroundColor: '#f4f4fa', borderBottom: '1px solid #cccccc', flex: "0 0 auto"}}>
            <Toolbar>
              <Typography variant="h6" color="inherit" style={{ fontSize: '16px', fontWeight: '600'}}>
                {chatId !== null
                  ? publicChatData.publicChats.find((p) => p.id === chatId)?.email
                  : 'パブリックチャット'}
              </Typography>
            </Toolbar>
          </AppBar>
          <RenderSingleChat
            sendMessage={sendMessage}
            email={
              publicChatData.publicChats.find((p) => p.id === chatId)?.email
            }
            messages={
              publicChatData.publicChats.find((p) => p.id === chatId)?.messages
            }
          />
        </div>
      </div>
    );
  }
};
