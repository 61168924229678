import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {DeleteOutlined, EditOutlined, UnlockOutlined} from "@ant-design/icons";
// import Icon from '@material-ui/core/Icon';
// import AddBox from '@material-ui/icons/AddBox';

export const PortRow = ({
  // port,
  door,
  // setAddDoorToPort,
  openDoor,
  // index,
  editDoor,
  setDeleteDoor,
}: // isSystemAdmin,
// setUpdatePort,
any) => (
  <TableRow>
    {/* {index === 0 ? (
      <TableCell rowSpan={port.doors.length}>
        {port.name}
        {isSystemAdmin ? (
          <>
            <Button
              style={{ float: 'right' }}
              onClick={() => setAddDoorToPort(port.id)}>
              <AddBox fontSize="small" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setUpdatePort(port)}>
              <Icon fontSize="small" color="primary">
                edit
              </Icon>
            </Button>
          </>
        ) : null}
      </TableCell>
    ) : null} */}
    <TableCell>{door.code}</TableCell>
    <TableCell style={{ color: door.status === 'FULL' ? 'red' : 'green' }}>
      {door.status}
    </TableCell>
    <TableCell>{door.babyCar ? door.babyCar.status : null}</TableCell>
    <TableCell>{door.isOpen ? 'OPEN' : 'CLOSED'}</TableCell>
    <TableCell>
      <Button
        style={{  height: '36px', backgroundColor: '#16a34a', boxShadow: 'none', padding: '6px 10px', minWidth: '52px' }}
        variant="contained"
        color="primary"
        onClick={() => openDoor(door.id)}>
        <UnlockOutlined style={{
          fontSize: '18px'
        }} />
      </Button>
    </TableCell>
    {/* {isSystemAdmin ? ( */}
    <TableCell style={{ display: 'flex', gap: '6px' , flexDirection: 'row' }}>
      <Button
        style={{  height: '36px', boxShadow: 'none', padding: '6px 10px', minWidth: '52px' }}
        variant="contained"
        color="primary"
        onClick={() => editDoor(door.code, door.id)}>
        <EditOutlined style={{
          fontSize: '18px'
        }} />
      </Button>
      <Button
        style={{  height: '36px', boxShadow: 'none', padding: '6px 10px', minWidth: '52px' }}
        variant="contained"
        color="secondary"
        onClick={() => setDeleteDoor(door.id)}>
        <DeleteOutlined style={{
          fontSize: '18px'
        }}/>
      </Button>
    </TableCell>
    {/* ) : null} */}
  </TableRow>
);
