import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';

export const BabyCarAddButton = ({
  addBabyCar,
}: {
  addBabyCar: (v: { babycarNumber: string }) => void;
}): JSX.Element => {
  const [form] = Form.useForm();
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button
        type="primary"
        shape="circle"
        onClick={() => {
          setModalOpen(true);
        }}>
        +
      </Button>
      <Modal
        title="Basic Modal"
        visible={isModalOpen}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          form.resetFields();
          setModalOpen(false);
        }}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={(values) => {
            addBabyCar(values);
            form.resetFields();
            setModalOpen(false);
          }}
          initialValues={{ remember: true }}>
          <Form.Item
            label="ベビーカー番号"
            name="babycarNumber"
            rules={[{ required: true, message: 'ベビーカー番号' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
