import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { HomeComponent } from '@src/components/Home/HomeComponent';
import {subscriptionClient} from '@src/share';

const drawerWidth = 240;

const styles = (theme: any) =>
  makeStyles({
    root: {
      height: '100vh',
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: '100vh',
      overflow: 'auto',
      display: 'flex',
      paddingTop: '70px',
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
    signOutButton: {
      border: '2px solid #fff',
      color: '#fff',
      textTransform: 'none',
      // '&:hover': {
      //   backgroundColor: '#283593',
      // },
    },
  });

class AdminDash extends React.Component<any, any> {
  state: any = {
    open: true,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  signOut = () => {
    subscriptionClient.close();
    localStorage.removeItem('token');
    localStorage.removeItem('firebaseToken');
    this.props.history.push('/login');
  };

  render() {
    return (
      <HomeComponent
        classes={this.props.classes}
        open={this.state.open}
        history={this.props.history}
        handleDrawerClose={this.handleDrawerClose}
        handleDrawerOpen={this.handleDrawerOpen}
        signOut={this.signOut}>
        {this.props.children}
      </HomeComponent>
    );
  }
}

export const Home = withStyles(styles)(AdminDash);
