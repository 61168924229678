import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

export const ProductModal = ({
  state,
  handleClose,
  onChangeHandler,
  addProduct,
  updateProduct,
}: any) => (
  <Dialog
    open={state.isModelOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
    <DialogTitle id="confirmation-dialog-title">
      {state.editProductId ? '商品編集' : '商品追加'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <TextField
          style={{ width: '100%', alignSelf: 'center' }}
          required
          value={state.name}
          onChange={onChangeHandler}
          name="name"
          id="filled-required"
          label="商品名"
          margin="dense"
        />
          <TextField
              style={{ width: '100%', alignSelf: 'center' }}
              required
              value={state.nameEn}
              onChange={onChangeHandler}
              name="nameEn"
              id="filled-required"
              label="商品名（英語）"
              margin="dense"
          />
        <TextField
          style={{ width: '100%', alignSelf: 'center' }}
          required
          value={state.code}
          onChange={onChangeHandler}
          name="code"
          id="filled-required"
          label="商品コード"
          margin="dense"
        />
        <TextField
          style={{ width: '100%', alignSelf: 'center' }}
          value={state.description}
          onChange={onChangeHandler}
          name="description"
          id="filled"
          label="説明"
          margin="dense"
        />
        <TextField
          style={{ width: '100%', alignSelf: 'center' }}
          required
          value={state.price}
          onChange={onChangeHandler}
          name="price"
          id="filled-required"
          label="価格"
          margin="dense"
        />
        <TextField
          style={{ width: '100%', alignSelf: 'center' }}
          required
          value={state.duration}
          onChange={onChangeHandler}
          name="duration"
          id="filled-required"
          label="貸出時間"
          margin="dense"
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          if (state.editProductId) {
            updateProduct();
          } else {
            addProduct();
          }
        }}
        color="primary">
        {state.editProductId ? 'Apply' : 'Add'}
      </Button>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);
