import { Modal, Typography } from 'antd';
import { useState } from 'react';

const { Title } = Typography;

export const useDeleteBabyCarModal = ({
  deleteBabyCar,
}: {
  deleteBabyCar: (id: string) => void;
}): [() => JSX.Element | null, (id: string | null) => void] => {
  const [deleteBabyCarId, setDeleteBabyCarId] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const openDeleteBabyCar = (id: string | null) => {
    setDeleteBabyCarId(id);
    setModalOpen(true);
  };
  return [
    () => (
      <Modal
        title="Delete Babycar"
        visible={isModalOpen}
        onOk={() => {
          if (!deleteBabyCarId) {
            return;
          }
          deleteBabyCar(deleteBabyCarId);
          setModalOpen(false);
        }}
        onCancel={() => {
          setDeleteBabyCarId(null);
          setModalOpen(false);
        }}>
        <Title>Delete this BabyCar?</Title>
      </Modal>
    ),
    openDeleteBabyCar,
  ];
};
