import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { facilitiesQuery_facilities } from '@src/generated/graphql';
import { useFormikContext } from 'formik';
import { ModalCenter } from './ModalCenter';
import { ModalLeft } from './ModalLeft';
import { ModalRight } from './ModalRight';

const contentTextStyle = {
  marginLeft: '2%',
  marginRight: '2%',
  display: 'flex',
  fontSize: '1vmin',
};
const inputStyle = {
  marginTop: '1px',
  marginBottom: '1em',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  borderRadius: '3px',
};
const itemText = {
  color: '#878787',
  fontSize: '1.3vmin',
};
const itemFacility = {
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
  height: '25px',
  padding: '2px',
  paddingLeft: '10px',
  marginLeft: '1px',
  marginBottom: '2px',
  marginRight: '0px',
};
const theme = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: '1.5vmin',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: '#FFFFFF',
      },
      inputMarginDense: {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
    MuiButtonBase: {
      root: {},
    },
    MuiSwitch: {
      track: {
        top: '42%',
        left: '49%',
        width: '43px',
        borderRadius: '50px',
      },
      thumb: {
        width: '18px',
        height: '18px',
      },
      colorSecondary: {
        '&$checked': {
          color: '#FFFFFF',
          transform: 'translateX(21px)',
          '& + $bar': {
            opacity: 1,
            backgroundColor: '#1b58ff',
          },
        },
      },
    },
    MuiSelect: {
      select: {
        width: 'Calc(100% - 10px)',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '5px',
        paddingRight: '2px',
      },
      icon: {
        color: '#ff7875',
        top: 'calc(5px - 20px)',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '0%',
      },
      colorPrimary: {
        color: '#FFFFFF',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '50px',
      },
    },
  },
});
type FacilityModalType = {
  isModalOpen: boolean;
  editFacilityId: string;
  modalCloseFunc: () => Promise<void>;
};
export const FacilityModal = ({
  isModalOpen,
  editFacilityId,
  modalCloseFunc,
}: FacilityModalType) => {
  const { handleSubmit, handleChange, handleReset, values } =
    useFormikContext<facilitiesQuery_facilities>();
  const handleClose = () => {
    handleReset();
    modalCloseFunc();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="xl"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle id="confirmation-dialog-title">
        {editFacilityId ? '施設情報編集' : '施設情報作成'}
      </DialogTitle>
      <DialogContent
        style={{
          marginLeft: '20px',
          marginRight: '20px',
          width: '110vmin',
          backgroundColor: '#F5F5F5',
          alignSelf: 'center',
          flexDirection: 'row',
          display: 'flex',
        }}>
        <DialogContentText
          style={{
            ...contentTextStyle,
            width: '45vmin',
            flexDirection: 'column',
          }}>
          <MuiThemeProvider theme={theme}>
            <ModalLeft
              values={values}
              handleChange={handleChange}
              itemText={itemText}
              inputStyle={inputStyle}
            />
          </MuiThemeProvider>
        </DialogContentText>
        <DialogContentText
          style={{
            ...contentTextStyle,
            width: '35vmin',
            flexDirection: 'column',
          }}>
          <ModalCenter
            values={values}
            handleChange={handleChange}
            theme={theme}
            itemText={itemText}
            inputStyle={inputStyle}
            itemFacility={itemFacility}
          />
        </DialogContentText>
        <DialogContentText
          style={{
            ...contentTextStyle,
            width: '35vmin',
            flexDirection: 'column',
          }}>
          <MuiThemeProvider theme={theme}>
            <ModalRight
              values={values}
              handleChange={handleChange}
              itemText={itemText}
              inputStyle={inputStyle}
              itemFacility={itemFacility}
            />
          </MuiThemeProvider>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            paddingTop: '3px',
            paddingBottom: '3px',
          }}
          onClick={() => handleClose()}
          variant="outlined">
          キャンセル
        </Button>
        <Button
          style={{
            marginRight: '3%',
            backgroundColor: '#12C670',
            borderColor: '#12C670',
            color: '#FFFFFF',
            padding: '3px',
          }}
          onClick={() => {
            handleSubmit();
          }}
          variant="outlined">
          {editFacilityId ? '編集' : '作成'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
