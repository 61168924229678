// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.reservation-payment-table-wrapper .ant-table-thead > tr > th,
.reservation-payment-table-wrapper .ant-table-tbody > tr > td{
    padding: 8px 14px;
}

.reservation-payment-table-wrapper .ant-table {
    min-height: 430px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Reservations/ReservationPaymentInfoModal.css"],"names":[],"mappings":";AACA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.reservation-payment-table-wrapper .ant-table-thead > tr > th,\n.reservation-payment-table-wrapper .ant-table-tbody > tr > td{\n    padding: 8px 14px;\n}\n\n.reservation-payment-table-wrapper .ant-table {\n    min-height: 430px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
