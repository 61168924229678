import CircularProgress from '@material-ui/core/CircularProgress';
import { MySnackBar } from '@src/components/MySnackBar';
import { PortsComponent } from '@src/components/Ports/component';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { ValidatorForm } from 'react-material-ui-form-validator';

class Ports extends React.Component<any, any> {
  unsubscribe: any = null;

  state: any = {
    isModelOpen: this.props.isModalOpen || false,
    editPortId: null,
    doorId: null,
    editDoorId: null,
    doorCode: null,
    portId: null,
    rasPiId: '',
    name: '',
    nameEn: '',
    description: '',
    descriptionEn: '',
    zipCode: null,
    address: '',
    addressEn: '',
    weekdayStart: null,
    weekdayEnd: null,
    weekendStart: null,
    weekendEnd: null,
    openingHourNote: '',
    numberOfDoors: '',
    longitude: null,
    latitude: null,
    note: '',
    notCleanEmail: '',
    notificationEmail: '',
    discountPercent: null,
    discountedStartDate: null,
    seedGoogleMap: 1
  };

  componentDidMount() {
    this.unsubscribe = this.props.subscribe();
    ValidatorForm.addValidationRule('isValidTime', (value) => {
      if (!value) {
        return true; // This is optional field
      }
      return moment(value, 'HH:mm').isValid();
    });
    ValidatorForm.addValidationRule('requiredBothInputWeekdayTime', (value) => {
      const { weekdayStart } = this.state;
      return (weekdayStart && value) || (!weekdayStart && !value);
    });
    ValidatorForm.addValidationRule('requiredBothInputWeekendTime', (value) => {
      const { weekendStart } = this.state;
      return (weekendStart && value) || (!weekendStart && !value);
    });
    ValidatorForm.addValidationRule('isValiedWeekdayTime', (value) => {
      const { weekdayStart } = this.state;
      if (!weekdayStart) {
        return true;
      }
      if (moment(weekdayStart, 'HH:mm').isBefore(moment(value, 'HH:mm'))) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule('isValiedWeekendTime', (value) => {
      const { weekendStart } = this.state;
      if (!weekendStart) {
        return true;
      }
      if (moment(weekendStart, 'HH:mm').isBefore(moment(value, 'HH:mm'))) {
        return true;
      }
      return false;
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'latitude' || e.target.name === 'longitude') {
      this.resetMap()
    }
  };

  addPort = async () => {
    await this.props.addPort({
      variables: {
        data: {
          note: this.state.note,
          name: this.state.name,
          nameEn: this.state.nameEn,
          rasPiId: this.state.rasPiId,
          address: this.state.address,
          zipCode: this.state.zipCode,
          addressEn: this.state.addressEn,
          descriptionEn: this.state.descriptionEn,
          openingHourNote: this.state.openingHourNote,
          weekdayStart: moment(this.state.weekdayStart, 'HH:mm'),
          weekdayEnd: moment(this.state.weekdayEnd, 'HH:mm'),
          weekendStart: moment(this.state.weekendStart, 'HH:mm'),
          weekendEnd: moment(this.state.weekendEnd, 'HH:mm'),
          numberOfDoors: parseInt(this.state.numberOfDoors, 10),
          longitude: parseFloat(this.state.longitude),
          latitude: parseFloat(this.state.latitude),
          notCleanEmail: this.state.notCleanEmail,
          notificationEmail: this.state.notificationEmail,
          discountPercent: +this.state.discountPercent,
          discountedStartDate: this.state.discountedStartDate,
        },
      },
    });
    await this.props.portsQuery.refetch();
    this.setState((prevState) => ({
      isModelOpen: !prevState.isModelOpen,
    }));
    this.props.history.push('/ports');
  };

  setUpdatePort = (port) => {
    const weekdayStart = moment(port.weekdayStart);
    const weekdayEnd = moment(port.weekdayEnd);
    const weekendStart = moment(port.weekendStart);
    const weekendEnd = moment(port.weekendEnd);
    this.setState({
      editPortId: port.id,
      rasPiId: port.rasPiId,
      name: port.name,
      nameEn: port.nameEn,
      description: port.description,
      descriptionEn: port.descriptionEn,
      zipCode: port.zipCode,
      address: port.address,
      addressEn: port.addressEn,
      weekdayStart: weekdayStart.isValid()
        ? weekdayStart.format('HH:mm')
        : null,
      weekdayEnd: weekdayEnd.isValid() ? weekdayEnd.format('HH:mm') : null,
      weekendStart: weekendStart.isValid()
        ? weekendStart.format('HH:mm')
        : null,
      weekendEnd: weekendEnd.isValid() ? weekendEnd.format('HH:mm') : null,
      openingHourNote: port.openingHourNote,
      numberOfDoors: port.numberOfDoors,
      longitude: port.longitude,
      latitude: port.latitude,
      note: port.note,
      notCleanEmail: port.notCleanEmail,
      notificationEmail: port.notificationEmail,
      discountPercent: +port.discountPercent,
      discountedStartDate: port.discountedStartDate ? moment(port.discountedStartDate).format("YYYY-MM-DD") : null,
    });
  };

  updatePort = async () => {
    await this.props.updatePort({
      variables: {
        portId: this.state.editPortId,
        data: {
          note: this.state.note,
          name: this.state.name,
          nameEn: this.state.nameEn,
          rasPiId: this.state.rasPiId,
          address: this.state.address,
          zipCode: this.state.zipCode,
          addressEn: this.state.addressEn,
          description: this.state.description,
          descriptionEn: this.state.descriptionEn,
          notCleanEmail: this.state.notCleanEmail,
          weekdayStart: moment(this.state.weekdayStart, 'HH:mm'),
          weekdayEnd: moment(this.state.weekdayEnd, 'HH:mm'),
          weekendStart: moment(this.state.weekendStart, 'HH:mm'),
          weekendEnd: moment(this.state.weekendEnd, 'HH:mm'),
          openingHourNote: this.state.openingHourNote,
          longitude: parseFloat(this.state.longitude),
          latitude: parseFloat(this.state.latitude),
          notificationEmail: this.state.notificationEmail,
          discountPercent: +this.state.discountPercent,
          discountedStartDate: this.state.discountedStartDate,
        },
      },
    });
    await this.props.portsQuery.refetch();
    this.setState({ editPortId: null });
  };

  updatePortImageSoftDelete = async ({ portId, imageUrlKey }) => {
    await this.props.updatePort({
      variables: {
        portId: portId,
        data: {
          [imageUrlKey]: '',
        },
      },
    });
    await this.props.portsQuery.refetch();
  };

  deleteDoor = async (doorId) => {
    await this.props.deleteDoor({
      variables: {
        doorId,
      },
    });
    this.props.portsQuery.refetch();
    this.setState({ doorId: null });
  };

  deleteDoorCancel = () => {
    this.setState({ doorId: null });
  };

  updateDoorCancel = () => {
    this.setState({
      editDoorId: null,
    });
  };

  updateDoor = async () => {
    await this.props.updateDoor({
      variables: {
        doorId: this.state.editDoorId,
        data: {
          code: this.state.doorCode,
        },
      },
    });
    await this.props.portsQuery.refetch();
    this.setState({ editDoorId: null });
  };

  addDoorToPort = async (portId) => {
    await this.props.addDoorToPort({
      variables: {
        portId,
      },
    });
    await this.props.portsQuery.refetch();
    this.setState({ portId: null });
  };

  addDoorToPortCancel = () => {
    this.setState({ portId: null });
  };

  setAddDoorToPort = (portId) => {
    this.setState({
      portId,
    });
  };

  handleOpen = () => {
    this.props.history.push('/ports/add');
    this.setState({
      isModelOpen: true,
    });
  };

  handleClose = () => {
    this.props.history.push('/ports');
    this.setState({
      isModelOpen: false,
      editPortId: null,
      doorId: null,
      editDoorId: null,
      doorCode: null,
      portId: null,
      rasPiId: '',
      name: '',
      nameEn: '',
      description: '',
      descriptionEn: '',
      address: '',
      addressEn: '',
      zipCode: null,
      weekdayStart: null,
      weekdayEnd: null,
      weekendStart: null,
      weekendEnd: null,
      openingHourNote: '',
      numberOfDoors: '',
      longitude: null,
      latitude: null,
      note: '',
      notCleanEmail: '',
      notificationEmail: '',
      discountPercent: null,
      discountedStartDate: null,
      seedGoogleMap: 1
    });
  };
  resetMap = () => {
    this.setState({ seedGoogleMap: Math.random() });
  }
  openDoor = async (doorId) => {
    try {
      await this.props.openDoor({
        variables: {
          doorId,
        },
      });
      this.props.showAlert('Successfully opened the door', 'success');
    } catch (error) {
      this.props.showAlert(
        error.message.replace('GraphQL error: ', ''),
        'error',
      );
    }
  };

  editDoor = (doorCode, editDoorId) => {
    this.setState({
      doorCode,
      editDoorId,
    });
  };

  setDeleteDoor = (doorId) => {
    this.setState({
      doorId,
    });
  };

  checkRasPi = async (rasPiId) => {
    try {
      const result = await this.props.checkRasPi({
        variables: {
          rasPiId,
        },
      });
      if (result?.data?.checkRasPi) {
        this.props.showAlert(
          'ラズパイのチェック機能送信に成功しました',
          'success',
        );
      }
    } catch (error) {
      this.props.showAlert(
        error.message.replace(
          'check error: ',
          'ラズパイのチェック機能送信に失敗しました',
        ),
        'error',
      );
    }
  };
  getGPSGoogleMap = (ev)=> {
    this.setState({
      latitude: parseFloat(ev?.detail?.latLng?.lat),
      longitude: parseFloat(ev?.detail?.latLng?.lng),
    });
  }
  rebootRasPi = async (rasPiId) => {
    const alert = confirm('ラズパイを再起動してよろしいですか？');
    if (alert) {
      try {
        const result = await this.props.rebootRasPi({
          variables: {
            rasPiId,
          },
        });
        if (result?.data?.rebootRasPi) {
          this.props.showAlert('ラズパイ再起動送信に成功しました', 'success');
        }
      } catch (error) {
        this.props.showAlert(
          error.message.replace(
            'reboot error: ',
            'ラズパイ再起動送信に失敗しました',
          ),
          'error',
        );
      }
    }
  };

  render() {
    const {
      portsQuery: { ports, loading, error, refetch },
      isSystemAdmin,
    } = this.props;
    if (loading) return <CircularProgress />;
    if (error)
      return (
        <MySnackBar variant="error" message={error.graphQLErrors[0].message} />
      );
    return (
      <PortsComponent
        getGPSGoogleMap={this.getGPSGoogleMap}
        handleOpen={this.handleOpen}
        handleClose={this.handleClose}
        onChangeHandler={this.onChangeHandler}
        ports={ports}
        doorId={this.state.doorId}
        editDoorId={this.state.editDoorId}
        editDoor={this.editDoor}
        portId={this.state.portId}
        openDoor={this.openDoor}
        addDoorToPort={this.addDoorToPort}
        addDoorToPortCancel={this.addDoorToPortCancel}
        setAddDoorToPort={this.setAddDoorToPort}
        addPort={this.addPort}
        deleteDoor={this.deleteDoor}
        deleteDoorCancel={this.deleteDoorCancel}
        updateDoor={this.updateDoor}
        updateDoorCancel={this.updateDoorCancel}
        setDeleteDoor={this.setDeleteDoor}
        doorCode={this.state.doorCode}
        isSystemAdmin={isSystemAdmin}
        updatePort={this.updatePort}
        updatePortImageSoftDelete={this.updatePortImageSoftDelete}
        setUpdatePort={this.setUpdatePort}
        checkRasPi={this.checkRasPi}
        rebootRasPi={this.rebootRasPi}
        state={this.state}
        refetch={refetch}
        seedGoogleMap={this.state.seedGoogleMap}
      />
    );
  }
}

const portsQuery = gql`
  query portsQuery {
    ports {
      id
      name
      nameEn
      numberOfDoors
      rasPiId
      address
      addressEn
      zipCode
      longitude
      latitude
      weekdayStart
      weekdayEnd
      weekendStart
      weekendEnd
      openingHourNote
      description
      descriptionEn
      note
      imageUrl1
      imageUrl2
      imageUrl3
      notCleanEmail
      notificationEmail
      discountPercent
      discountedStartDate
      doors {
        id
        code
        status
        isOpen
        babyCar {
          id
          code
          status
          cleaningStatus
        }
      }
    }
  }
`;
const openDoor = gql`
  mutation OPEN_DOOR($doorId: ID!) {
    openDoorAdmin(doorId: $doorId)
  }
`;

const DOOR_SUBSCRIPTION = gql`
  subscription DOOR_SUBSCRIPTION {
    doorSubscription {
      id
      code
      status
      isOpen
    }
  }
`;

const ADDPORT = gql`
  mutation ADD_PORT($data: AddPortInput!) {
    addPort(data: $data) {
      id
    }
  }
`;
const DELETEDOOR = gql`
  mutation DELETE_DOOR($doorId: ID!) {
    deleteDoor(doorId: $doorId) {
      id
    }
  }
`;

const UPDATEDOOR = gql`
  mutation UPDATE_DOOR($doorId: ID!, $data: DoorUpdateInput) {
    updateDoor(doorId: $doorId, data: $data) {
      code
    }
  }
`;
const ADDDOORTOPORT = gql`
  mutation ADD_DOOR_TO_PROT($portId: ID!) {
    addDoorToPort(portId: $portId) {
      id
    }
  }
`;
const UPDATE_PORT = gql`
  mutation updatePort($portId: ID!, $data: PortUpdateInput) {
    updatePort(portId: $portId, data: $data) {
      id
    }
  }
`;

const CHECK_RASPI = gql`
  mutation checkRasPi($rasPiId: String!) {
    checkRasPi(rasPiId: $rasPiId)
  }
`;

const REBOOT_RASPI = gql`
  mutation rebootRasPi($rasPiId: String!) {
    rebootRasPi(rasPiId: $rasPiId)
  }
`;

export const PortsComponent1 = compose(
  graphql(UPDATE_PORT, {
    name: `updatePort`,
  }),
  graphql(ADDDOORTOPORT, {
    name: `addDoorToPort`,
  }),
  graphql(UPDATEDOOR, {
    name: `updateDoor`,
  }),
  graphql(DELETEDOOR, {
    name: `deleteDoor`,
  }),
  graphql(ADDPORT, {
    name: 'addPort',
  }),
  graphql(CHECK_RASPI, {
    name: `checkRasPi`,
  }),
  graphql(REBOOT_RASPI, {
    name: `rebootRasPi`,
  }),
  graphql(portsQuery, {
    name: 'portsQuery',
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props: any) => ({
      ...props,
      subscribe: () =>
        props.portsQuery.subscribeToMore({
          document: DOOR_SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }: any) => {
            if (!subscriptionData.data) {
              return prev;
            }
            return prev;
          },
        }),
    }),
  }),
  graphql(openDoor, {
    name: 'openDoor',
  }),
)(Ports);
