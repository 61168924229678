import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import { ProductRow } from './components/ProductRow';
import { ProductModal } from './components/ProductModal';

export const ProductsComponent1 = ({
  state = {},
  products = [],
  handleOpen,
  handleClose,
  onChangeHandler,
  addProduct,
  setUpdateProduct,
  isSystemAdmin,
  updateProduct,
}: any) => (
  <div style={{ width: '100%', height: '100%' }}>
    <h1>
      商品
      {isSystemAdmin ? (
        <Button style={{ float: 'right' }} onClick={handleOpen}>
          <AddBox fontSize="large" />
        </Button>
      ) : null}
    </h1>
    <ProductModal
      state={state}
      handleClose={handleClose}
      onChangeHandler={onChangeHandler}
      addProduct={addProduct}
      updateProduct={updateProduct}
    />
    <Paper elevation={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>商品名</TableCell>
            <TableCell>商品名（英語）</TableCell>
            <TableCell>商品コード</TableCell>
            <TableCell>説明</TableCell>
            <TableCell>価格</TableCell>
            <TableCell>貸出時間</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <ProductRow
              key={product.id}
              product={product}
              isSystemAdmin={isSystemAdmin}
              setUpdateProduct={setUpdateProduct}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  </div>
);
