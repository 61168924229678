import { notification } from 'antd';
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';

export const showSuccess = ({ description }: { description: string }): void => {
  notification.open({
    message: 'Success',
    description,
    icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  });
};

export const showFail = ({ description }: { description: string }): void => {
  notification.open({
    message: 'Fail',
    description,
    icon: <ExclamationCircleTwoTone twoToneColor="#eb2f96" />,
  });
};
