import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Router } from '@src/Router';
import {
  ApolloClient as ApolloClientOld,
  HttpLink,
  InMemoryCache as InMemoryCacheOld,
} from 'apollo-client-preset';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import AWS from 'aws-sdk';
import { ApolloProvider as ApolloProviderOld } from 'react-apollo';
import ReactDOM from 'react-dom';
import fetch from 'unfetch';
// import './index.css';
import './App.less';
import {subscriptionClient} from '@src/share';
import {registerServiceWorker} from "@src/serviceWorker";

const { REACT_APP_AWS_REGION, REACT_APP_AWS_IDENTITYPOOLID } = process.env;

const awsConfig = {
  region: REACT_APP_AWS_REGION,
  IdentityPoolId: REACT_APP_AWS_IDENTITYPOOLID as string,
};
AWS.config.region = awsConfig.region;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: awsConfig.IdentityPoolId,
});
const region = REACT_APP_AWS_REGION;
const poolId = REACT_APP_AWS_IDENTITYPOOLID;
new S3Client({
  region,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region }),
    identityPoolId: poolId as string,
  }),
});
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HTTP_ENDPOINT,
  fetch: fetch as any,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const tokenValue = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  operation.setContext({
    headers: {
      Authorization: tokenValue ? `Bearer ${tokenValue}` : '',
    },
  });
  if (forward) {
    return forward(operation);
  }
  return null;
});

// authenticated httplink
const httpLinkAuth = middlewareLink.concat(httpLink);

const wsLink = new WebSocketLink(subscriptionClient);

const link = split(
  // split based on operation type
  ({ query }: any) => {
    const s: any = getMainDefinition(query);
    const { kind, operation } = s;
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLinkAuth,
);

// apollo client setup
const client = new ApolloClient({
  link: ApolloLink.from([link]) as any,
  cache: new InMemoryCache(
      {
        typePolicies: {
          Query: {
            fields: {
              chatsPage: {
                read(existing, options) {
                  const {args} = options;
                  const text = args && args.text;
                  const helpQuestion = args && args.helpQuestion;
                  const allMember = args && args.allMember;
                  const inactiveUser = args && args.inactiveUser;
                  const key = `${text}_${helpQuestion}_${allMember}_${inactiveUser}`;
                  let chats: any[] = [];
                  if (existing && existing[key]) {
                    chats = existing[key] as any[];
                  }

                  return chats;
                },
                keyArgs: [],
                merge(existing, incoming, options) {
                  const {args} = options;
                  const offset = (args && args.offset) || 0;
                  const text = args && args.text;
                  const helpQuestion = args && args.helpQuestion;
                  const allMember = args && args.allMember;
                  const inactiveUser = args && args.inactiveUser;

                  const key = `${text}_${helpQuestion}_${allMember}_${inactiveUser}`;
                  const merged = existing ? {...existing} : {};
                  merged[key] = merged[key] ? [...merged[key]] : [];

                  for (let i = 0; i < incoming.length; ++i) {
                    merged[key][offset + i] = incoming[i];
                  }
                  return merged;
                },
              },
              messagesPage: {
                read(existing, options) {
                  const {args} = options;
                  const chatId = args && args.chatId;
                  const text = args && args.text;
                  const helpQuestion = args && args.helpQuestion;
                  const key = `${chatId}_${text}_${helpQuestion}`;
                  let messages: any[] = [];
                  if (existing && existing[key]) {
                    messages = [...existing[key]] as any[];
                  }
                  return messages.reverse();
                },
                keyArgs: [],
                merge(existing, incoming, options) {
                  const {args} = options;
                  const offset = (args && args.offset) || 0;
                  const chatId = args && args.chatId;
                  const text = args && args.text;
                  const helpQuestion = args && args.helpQuestion;
                  const key = `${chatId}_${text}_${helpQuestion}`;
                  const merged = existing ? {...existing} : {};

                  merged[key] = merged[key] ? [...merged[key]] : [];
                  if (!incoming || (incoming && incoming.length === 0)) {
                    return merged
                  } else if (offset === 0 && merged[key].length > 0) {
                    //append new message
                    const firstItem = merged[key][0]
                    let idx = -1
                    if (firstItem) {
                      idx = incoming?.findIndex((val: any) => val.__ref === firstItem.__ref)
                    }
                    if (idx === -1) {
                      merged[key] = [...incoming]
                    } else {
                      merged[key] = [...incoming.slice(0, idx), ...merged[key]]
                    }
                  } else {
                    // append old message
                    if (offset <= merged[key].length) {
                      for (let i = 0; i < incoming.length; ++i) {
                        merged[key][offset + i] = incoming[i];
                      }
                    }
                  }
                  return merged;
                },
              },
            },
          },
        },
      }
  ),
  connectToDevTools: true,
});

const clientOld = new ApolloClientOld({
  link: ApolloLink.from([link]),
  cache: new InMemoryCacheOld(),
  connectToDevTools: true,
});

const token = localStorage.getItem('token');

ReactDOM.render(
  <ApolloProviderOld client={clientOld}>
    <ApolloProvider client={client}>
      <Router token={token} />
    </ApolloProvider>
  </ApolloProviderOld>,
  document.getElementById('root'),
);

registerServiceWorker()
