import React, {useState} from 'react';
import {gql} from 'apollo-boost';
import {compose, graphql} from 'react-apollo';
import Button from '@material-ui/core/Button';
import {Status} from '@src/generated/graphql';
import {showFail} from '@src/components/Alert';
import Typography from "@material-ui/core/Typography";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import {Tab, Tabs} from "@material-ui/core";
import useWindowDimensions from "@src/hooks/useWindowDimensions";

const allowDrop = (e) => {
  e.preventDefault();
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        height: 'calc(100% - 48px)',
      }}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '80vh',
    gap: '10px',
  },
  layoutBottom: {
    height: '60%',
    width: '100%',
    overflow: 'auto',
    paddingRight: '8px'
  },
  layoutTop: {
    width: '100%',
    border: '2px solid #d1d5db',
    borderRadius: '10px',
    height: '40%'
  },
}
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',

    '& .MuiAppBar-colorPrimary': {
      background: '#f4f4fa',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      boxShadow: 'none',

      '& .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator': {
        background: '#fa4e69',
        height: '3px'
      },

      '& .MuiTab-wrapper': {
        fontWeight: '500',
        fontSize: '15px',
        color: '#000',
      }
    },

  },
}));

const Manager = (props: any): JSX.Element => {
  const value: any = {
    babyCarId: '',
    doorId: '',
  };
  const [babyCarValue, setBabyCarValue] = useState(value);
  const [valueTab, setValueTab] = React.useState(0);
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const drag = (e) => {
    setBabyCarValue({babyCarId: e.target.id});
    e.dataTransfer.setData('text', e.target.id);
  };

  const drop = async (e, {babyCarStatus}) => {
    const dropApprove =
      e?.target?.id &&
      e?.target?.id !== dropStatus.id &&
      e?.target?.id !== '' &&
      e?.target?.id !== dropStatus.door &&
      babyCarStatus === Status.INSIDE;
    if (dropStatus.door === null || dropApprove) {
      e.preventDefault();
      try {
        await props.updateManagerBabyCar({
          variables: {
            babyCarId: babyCarValue.babyCarId,
            doorId: e.target.id,
            data: {status: 'INSIDE'},
          },
        });
      } catch (err: any) {
        showFail({description: err.message.replace('GraphQL error: ', '')});
      } finally {
        await props.ports.refetch();
        await props.babyCars.refetch();
      }
    } else if (babyCarStatus === Status.OUTSIDE) {
      e.preventDefault();
      try {
        await props.updateManagerBabyCar({
          variables: {
            babyCarId: babyCarValue.babyCarId,
            doorId: e.target.id,
            data: {status: 'OUTSIDE'},
          },
        });
      } catch (err: any) {
        showFail({description: err.message.replace('GraphQL error: ', '')});
      } finally {
        await props.ports.refetch();
        await props.babyCars.refetch();
      }
    } else if (babyCarStatus === Status.SPARE) {
      e.preventDefault();
      try {
        await props.updateManagerBabyCar({
          variables: {
            babyCarId: babyCarValue.babyCarId,
            doorId: e.target.id,
            data: {status: 'SPARE'},
          },
        });
      } catch (err: any) {
        showFail({description: err.message.replace('GraphQL error: ', '')});
      } finally {
        await props.ports.refetch();
        await props.babyCars.refetch();
      }
    }
  };

  if (props.babyCars.loading || props.ports.loading) {
    return <div>Loading...</div>;
  }

  const dropStatus = props.babyCars.babyCars.find((babyCar) => {
    return babyCar.id === babyCarValue.babyCarId;
  });

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: '1rem',
    },
  }))(Tooltip);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };
  return (
    <div style={{...styles.container, padding: width < 678 ? '20px' : 0}}>
      <div style={styles.layoutTop}>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={valueTab} onChange={handleChange} aria-label="simple tabs example">
              <Tab label={width && width >= 600 ? "RESERVED BABYCARS" : "RESERVED"} {...a11yProps(0)} />
              <Tab label={width && width >= 600 ? "SPARE BABYCARS" : "SPARE"} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={valueTab} index={0}>
            <div
              onDrop={(e) => {
                if (dropStatus.status === Status.INSIDE) {
                  drop(e, {babyCarStatus: Status.OUTSIDE});
                }
              }}
              onDragOver={allowDrop}
              style={{
                backgroundColor: 'rgba(255,255,255,0.7)',
                overflow: 'auto',
                height: '100%'
              }}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '5px',
                  gap: '6px',
                  padding: '10px',
                }}>
                {props.babyCars.babyCars.map((babyCar) => {
                  if (babyCar.status === Status.OUTSIDE) {
                    return (
                      <div draggable onDragStart={drag} id={babyCar.id} key={babyCar.id}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: '#fa4e69',
                            color: '#FFFFFF',
                            boxShadow: 'none',
                            marginRight: '5px',
                            marginBottom: '5px',
                            maxHeight: width < 678 ? '30px' : '42px',
                            height: '100%'
                          }}>
                          <span
                            className="test"
                            style={{
                              display: 'inline-block',
                              maxHeight: '50px',
                              wordBreak: 'break-all',
                              lineHeight: '30px',
                              maxWidth: '150px',
                            }}>
                            <LightTooltip title={babyCar.code} placement="top">
                              <Typography
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontSize: '14px',
                                }}>
                               {babyCar.code}
                             </Typography>
                         </LightTooltip>
                          </span>
                        </Button>
                      </div>
                    );
                  } else return;
                })}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={valueTab} index={1}>
            <div
              onDrop={(e) => {
                if (dropStatus.status === Status.INSIDE) {
                  drop(e, {babyCarStatus: Status.SPARE});
                }
              }}
              onDragOver={allowDrop}
              style={{
                backgroundColor: 'rgba(255,255,255,0.7)',
                overflow: 'auto',
                height: '100%'
              }}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '5px',
                  gap: '6px',
                  padding: '10px',
                }}>
                {props.babyCars.babyCars.map((babyCar) => {
                  if (babyCar.status === Status.SPARE) {
                    return (
                      <div draggable onDragStart={drag} id={babyCar.id} key={babyCar.id}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: '#fa4e69',
                            color: '#FFFFFF',
                            boxShadow: 'none',
                            marginRight: '5px',
                            marginBottom: '5px',
                            maxHeight: width < 678 ? '30px' : '42px',
                            height: '100%'
                          }}>
                          <span
                            className="test"
                            style={{
                              display: 'inline-block',
                              maxHeight: '50px',
                              wordBreak: 'break-all',
                              lineHeight: '30px',
                              maxWidth: '150px',
                            }}>
                             <LightTooltip title={babyCar.code} placement="top">
                                  <Typography
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      fontSize: '14px',
                                    }}>
                                   {babyCar.code}
                                 </Typography>
                             </LightTooltip>
                          </span>
                        </Button>
                      </div>
                    );
                  } else return;
                })}
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
      <div style={styles.layoutBottom}>
        {props.ports.ports.map((port) => (
          <div
            id={port.id}
            key={port.id}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <span
              style={{
                marginTop: '20px',
                marginBottom: '10px',
                fontSize: '15px',
                fontWeight: '600',
                zIndex: 1,
                textTransform: 'uppercase',
              }}>
              {port.name}:DOOR
            </span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                maxWidth: width < 410 ? '100%' : 'max-content',
                border: 'solid 2px #d1d5db',
                borderRadius: '10px',
                width: '100%'
              }}>
              {port.doors.map((door, index) => (
                <div
                  id={door.id}
                  key={door.id}
                  onDrop={(e: any) => {
                    if (e?.target?.id !== '') {
                      drop(e, {babyCarStatus: Status.INSIDE});
                    }
                  }}
                  onDragOver={allowDrop}
                  style={{
                    flexShrink: 2,
                    height: width < 678 ? '40px' : '50px',
                    width: width < 678 && width >= 410 ? '120px' : width < 410 ? '100%' : '170px',
                    minWidth: width < 678 && width >= 410  ? '120px' : width < 410 ? '100%' : '170px',
                    alignContent: 'center',
                    display: 'flex',
                    margin: '5px',
                    borderRight: (+index + 1) !== +port.doors.length && width >= 410 ? 'solid 2px #d1d5db' : 'none',
                    // borderRight: 'solid 2px #d1d5db',
                  }}>
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#d1d5db',
                      marginRight: '10px',
                    }}>
                    {index + 1}
                  </span>
                  {door.babyCar ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 'calc(100% - 35px)',
                      }}
                      draggable
                      onDragStart={drag}
                      id={door.babyCar.id}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#fa4e69',
                          color: '#FFFFFF',
                          boxShadow: 'none',
                          maxHeight: width < 678 ? '30px' : '42px',
                          height: '100%',
                        }}>
                          <span
                            className="test"
                            style={{
                              display: 'inline-block',
                              maxHeight: '50px',
                              wordBreak: 'break-all',
                              lineHeight: '30px',
                              width: '100%'
                            }}>
                            <LightTooltip title={door.babyCar.code} placement="top">
                              <Typography
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontSize: '14px',
                                }}>
                               {door.babyCar.code}
                             </Typography>
                            </LightTooltip>
                          </span>
                      </Button>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PORTS = gql`
    query PORTS {
        ports {
            id
            name
            doors {
                id
                status
                doorIndex
                babyCar {
                    id
                    status
                    code
                }
            }
        }
    }
`;

const BABYCARS = gql`
    query BABYCARS {
        babyCars {
            id
            status
            code
            door {
                id
            }
        }
    }
`;

const TOGGLE_DOOR_STATUS = gql`
    mutation TOGGLER($doorId: ID!) {
        toggleDoorStatus(doorId: $doorId) {
            id
        }
    }
`;
const UPDATE_MANAGER_BABYCAR = gql`
    mutation UPDATE_MANAGER_BABYCAR(
        $babyCarId: ID!
        $doorId: ID
        $data: BabyCarUpdateInput!
    ) {
        updateManagerBabyCar(babyCarId: $babyCarId, doorId: $doorId, data: $data) {
            id
        }
    }
`;

export const ManagerComponent = compose(
  graphql(PORTS, {name: 'ports', options: {fetchPolicy: 'network-only'}}),
  graphql(BABYCARS, {
    name: 'babyCars',
    options: {fetchPolicy: 'network-only'},
  }),
  graphql(TOGGLE_DOOR_STATUS, {name: 'toggleDoorStatus'}),
  graphql(UPDATE_MANAGER_BABYCAR, {name: 'updateManagerBabyCar'}),
)(Manager);
