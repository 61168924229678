import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  facilitiesQuery,
  facilitiesQuery_facilities,
} from '@src/generated/graphql';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { FacilityModal } from './components/FacilityModal';
import { FacilityOption } from './components/FacilityOption';

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '4px 4px 4px 24px',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '0px',
      },
    },
  },
});
export const fasilityDetail = ({ facilities, setTableValue }: any) => {
  <TableBody>
    {facilities.map((facility) => (
      <TableRow hover onClick={() => setTableValue(facility.id)}>
        <TableCell>{facility.imageUrls[0]}</TableCell>
        <TableCell>{facility.name}</TableCell>
        <TableCell>{facility.category}</TableCell>
      </TableRow>
    ))}
  </TableBody>;
};
type FacilitiesComponentType = {
  modalOpenFunc: () => Promise<void>;
  modalCloseFunc: () => Promise<void>;
  isModalOpen: boolean;
  editFacilityId: string;
  setEditFacilityId: React.Dispatch<React.SetStateAction<string>>;
  deleteFacility: (facilityId: any) => Promise<void>;
  updateFacilityImageSoftDelete: ({
    facilityId,
    imageUrlKey,
  }: {
    facilityId: any;
    imageUrlKey: any;
  }) => Promise<void>;
  facilities: facilitiesQuery | undefined;
  facilityValues: any;
  facilitiesDataRefetchFunc: () => Promise<void>;
};
export const FacilitiesComponent = ({
  modalOpenFunc,
  modalCloseFunc,
  isModalOpen,
  editFacilityId,
  setEditFacilityId,
  deleteFacility,
  updateFacilityImageSoftDelete,
  facilities,
  facilityValues,
  facilitiesDataRefetchFunc,
}: FacilitiesComponentType) => {
  const { setValues } = useFormikContext<facilitiesQuery_facilities>();

  const [tableValue, setTableValue] = useState<string>('');
  const [stations, setStations] = useState<string>('All');
  const imageDate = new Date().toLocaleString('ja');
  const stationsHandleChange = (e) => {
    setStations(e.target.value);
  };
  const newStation: any = [
    ...new Map(
      facilities?.facilities?.map((newFacility) => [
        newFacility.station,
        newFacility,
      ]),
    ).values(),
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <div
        key={editFacilityId ? editFacilityId : 'comp'}
        style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            paddingBottom: '20px',
            alignItems: 'flex-end',
            height: '100px',
          }}>
          <h1 style={{ marginRight: '50px' }}>施設情報</h1>
          <FormControl
            style={{ width: '250px', marginRight: '10px' }}
            variant="filled">
            <Select
              value={stations}
              onChange={stationsHandleChange}
              input={
                <InputBase
                  style={{
                    width: '30vmin',
                    height: '40px',
                    marginTop: '1px',
                    marginBottom: '1px',
                    marginRight: '10px',
                    paddingLeft: '10px',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '3px',
                  }}
                  id="ports-select"
                />
              }>
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              {newStation.map((facility) => (
                <MenuItem value={facility.station}>{facility.station}</MenuItem>
              ))}
            </Select>
            <FormHelperText>駅名を選択</FormHelperText>
          </FormControl>
          <Button
            onClick={() => modalOpenFunc()}
            style={{
              height: '40px',
              boxShadow: 'none',
              position: 'absolute',
              right: '20px',
              backgroundColor: '#4eaaff',
            }}
            variant="contained"
            color="primary">
            新規作成
          </Button>
        </div>
        <FacilityModal
          isModalOpen={isModalOpen}
          editFacilityId={editFacilityId}
          modalCloseFunc={modalCloseFunc}
        />
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              width: '50%',
              display: 'flex',
              border: '1px solid',
              borderColor: '#dedede',
              overflowX: 'scroll',
            }}>
            <Table style={{ width: '100vmin', marginBottom: '50px' }}>
              <TableHead
                style={{ width: '100vmin', backgroundColor: '#f0f2f5' }}>
                <TableRow>
                  <TableCell>画像</TableCell>
                  <TableCell>施設名</TableCell>
                  <TableCell>カテゴリー</TableCell>
                </TableRow>
              </TableHead>
              {stations == 'All' ? (
                <TableBody>
                  {facilities?.facilities?.map((facility) => (
                    <TableRow hover onClick={() => setTableValue(facility.id)}>
                      <TableCell>
                        <div style={{ width: '10vmin' }}>
                          <img
                            src={`${facility.imageUrl1}?${imageDate}` || ''}
                            width="100%"
                            alt=""></img>
                        </div>
                      </TableCell>
                      <TableCell>{facility.name}</TableCell>
                      <TableCell>{facility.category}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {facilities?.facilities
                    ?.filter((facility) => {
                      return facility.station == stations;
                    })
                    .map((facility) => (
                      <TableRow
                        hover
                        onClick={() => setTableValue(facility.id)}>
                        <TableCell>
                          <div style={{ width: '10vmin' }}>
                            <img
                              src={facility.imageUrl1 || ''}
                              width="100%"
                              alt=""></img>
                          </div>
                        </TableCell>
                        <TableCell>{facility.name}</TableCell>
                        <TableCell>{facility.category}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </div>
          <div
            style={{
              width: '50%',
              border: '1px solid',
              borderColor: '#dedede',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5em',
                overflowX: 'scroll',
              }}>
              {facilities?.facilities
                ?.filter((facility) => {
                  return facility.id == tableValue;
                })
                .map((faciOption) => {
                  const editSetValues = async () => {
                    setValues({
                      ...facilityValues(faciOption),
                    });
                  };
                  return (
                    <div key={faciOption?.id}>
                      <Button
                        style={{
                          margin: '1em',
                          height: '40px',
                          boxShadow: 'none',
                          position: 'absolute',
                          right: '20px',
                          backgroundColor: '#12C670',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          await editSetValues();
                          await setEditFacilityId(faciOption.id);
                          await modalOpenFunc();
                        }}>
                        編集
                      </Button>
                      <Button
                        style={{
                          margin: '1em',
                          height: '40px',
                          boxShadow: 'none',
                          position: 'absolute',
                          right: '20px',
                          bottom: '20px',
                          backgroundColor: '#faad14',
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => deleteFacility(faciOption.id)}>
                        削除
                      </Button>
                      <FacilityOption
                        key={faciOption?.id}
                        faciOption={faciOption}
                        updateFacilityImageSoftDelete={
                          updateFacilityImageSoftDelete
                        }
                        facilitiesDataRefetchFunc={facilitiesDataRefetchFunc}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};
