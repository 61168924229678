import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { RESERVATIONS_CONNECTION } from '@src/generated/graphql';
import moment from 'moment';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { ReservationCsvModal } from './components/reservationCsv';
import { ReservationRow } from './components/Row';

type ReservationsComponent1Type = {
  handleClose: () => void;
  reservationsData: RESERVATIONS_CONNECTION | undefined;
  reservationsAllData: RESERVATIONS_CONNECTION | undefined;
  unlock: (token: any) => Promise<void>;
  adminLock: (token: any) => Promise<void>;
  updateReservation: (reservationId: any, paymentStatus: any) => Promise<void>;
  finish: (reservationId: any) => Promise<void>;
  onChangePage: (_event: any, pageNumber: any) => void;
  onChangeRowsPerPage: (event: any) => void;
  page: number;
  rowsPerPage: number;
  isSystemAdmin: any;
  adminLockReservation: (portId: any, doorId: any) => Promise<void>;
  createChat?: (userId: any) => Promise<any>;
};

export const ReservationsComponent1 = ({
  handleClose,
  reservationsData,
  reservationsAllData,
  unlock,
  adminLock,
  updateReservation,
  finish,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rowsPerPage,
  isSystemAdmin,
  adminLockReservation,
  createChat,
}: ReservationsComponent1Type) => {
  const [priorityArray, setPriorityArray] = useState({
    item: [],
    total: reservationsData?.reservationsConnection?.total,
    __typename: 'ReservationConnection',
  });

  const csvInitHeders = [
    { label: '名前', key: 'fullName' },
    { label: '貸出ポート名', key: 'startPort' },
    { label: '返却ポート名', key: 'port' },
    { label: '貸出ドア番号', key: 'startDoor' },
    { label: '返却ドア番号', key: 'door' },
    { label: '受取状態', key: 'status' },
    { label: '通常価格', key: 'price' },
    { label: 'クーポン', key: 'coupon' },
    { label: '支払い状況', key: 'paymentStatus' },
    { label: '最終価格', key: 'lastPrice' },
    { label: '開始日', key: 'startDate' },
    { label: '返却日', key: 'endDate' },
    { label: '開始時間', key: 'startTime' },
    { label: '返却時間', key: 'endTime' },
    { label: 'プラン名', key: 'product' },
    { label: '延長回数', key: 'extendedTimes' },
  ];

  const csvInit = priorityArray?.item?.map((a: any) => {
    return {
      fullName:
        a?.user?.firstname && a?.user?.lastname
          ? `${a.user.lastname} ${a.user.firstname}`
          : '',
      startPort: a?.startPort?.name ? a?.startPort?.name : '',
      port: a?.port?.name ? a?.port?.name : '',
      door: a?.door?.doorIndex ? a?.door?.doorIndex : '',
      status: a?.status ? '返却済' : '貸出中',
      price: a?.issuedCoupon
        ? a?.taxedAmount + a?.issuedCoupon?.coupon?.discountAmount
        : a?.taxedAmount,
      coupon: a?.issuedCoupon ? a?.issuedCoupon?.coupon?.name : '',
      paymentStatus: a?.paymentStatus ? '支払済' : '未払い',
      lastPrice: a?.issuedCoupon ? a?.taxedAmount : a?.taxedAmount,
      startDate: a?.startTime
        ? `${moment(a.startTime).format('YYYY/MM/DD')}`
        : '',
      endDate: a.endTime ? `${moment(a.endTime).format('YYYY/MM/DD')}` : '',
      startTime: a?.startTime
        ? `${moment(a.startTime).format('HH:mm:ss')}`
        : '',
      endTime: a.endTime ? `${moment(a.endTime).format('HH:mm:ss')}` : '',
      product: a?.product?.name ? a?.product?.name : '',
      extendedTimes: a?.extendedTimes ? a?.extendedTimes : '',
    };
  });

  return (
    <div style={{ height: '100%' }}>
      <h1>予約</h1>
      <div>
        <ReservationCsvModal
          handleClose={handleClose}
          setPriorityArray={setPriorityArray}
          reservationsData={reservationsAllData}
        />
        {priorityArray.item.length > 0 ? (
          <div
            style={{
              margin: 10,
            }}>
            <CSVLink
              data={csvInit}
              headers={csvInitHeders}
              filename={`sharebuggy${moment().format('YYYY-MM-DD')}.csv`}
              style={{
                borderWidth: '3px',
                borderColor: '#dedede',
                borderStyle: 'solid',
                borderRadius: '3px',
              }}>
              CSVDownload
            </CSVLink>
          </div>
        ) : (
          <div style={{ marginTop: '20px', marginBottom: '8px', fontWeight: '600' }}>検索条件が当てはまらないか、未検索状態です。</div>
        )}
      </div>
      <Paper style={{ width: '100%', overflowX: "auto" }} elevation={2}>
        <Table style={{ backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ whiteSpace: "nowrap" }}>番号</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>名前</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>貸出ポート名</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>貸出ドア番号</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>返却ポート名</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>返却ドア番号</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>受取（返却）完了</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>受取状態</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>返却開始</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>通常価格</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>クーポン</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>支払い状況</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>最終価格</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>開始時間</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>返却時間</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>電話番号</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>プラン名</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>延長回数</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>決済状況</TableCell>
              <TableCell style={{ whiteSpace: "nowrap", visibility: 'hidden' }}> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priorityArray.item.length > 0
              ? priorityArray?.item?.map((reservation: any, index) => (
                  <ReservationRow
                    key={reservation?.id}
                    index={index}
                    reservation={reservation}
                    unlock={unlock}
                    adminLock={adminLock}
                    finish={finish}
                    updateReservation={updateReservation}
                    isSystemAdmin={isSystemAdmin}
                    adminLockReservation={adminLockReservation}
                    createChat={createChat}
                  />
                ))
              : reservationsData?.reservationsConnection?.item?.map(
                  (reservation, index) => (
                    <ReservationRow
                      key={reservation.id}
                      index={index}
                      reservation={reservation}
                      unlock={unlock}
                      adminLock={adminLock}
                      finish={finish}
                      updateReservation={updateReservation}
                      isSystemAdmin={isSystemAdmin}
                      adminLockReservation={adminLockReservation}
                      createChat={createChat}
                    />
                  ),
                )}
          </TableBody>
          {priorityArray.item.length > 0 ? null : (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page || 0}
              rowsPerPage={rowsPerPage || 25}
              count={reservationsData?.reservationsConnection?.total || 0}
            />
          )}
        </Table>
      </Paper>
    </div>
  );
};
