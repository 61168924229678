import { DeleteOutlined } from '@ant-design/icons';
import { ImgDetail } from '@src/components/Ports/components/ImagDetail';
import { facilitiesQuery_facilities } from '@src/generated/graphql';
import { Button } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';

const itemText = {
  marginTop: '15px',
  marginBottom: '5px',
  color: '#878787',
  fontSize: '1.3vmin',
};
const itemContener = {
  display: 'flex',
  alignItems: 'center',
  width: '45px',
};
const itemName = {
  display: 'flex',
  marginTop: '5px',
  color: '#878787',
  fontSize: '1vmin',
};
const itemWeektime = {
  display: 'flex',
  fontSize: '1.5vmin',
};
type facilityOptionType = {
  faciOption: facilitiesQuery_facilities;
  updateFacilityImageSoftDelete: ({
    facilityId,
    imageUrlKey,
  }: {
    facilityId: string | null;
    imageUrlKey: string;
  }) => Promise<void>;
  facilitiesDataRefetchFunc: () => Promise<void>;
};
type imageUrlComponentType = {
  imageUrl: string | null;
  imageUrlKey: string;
};
export const FacilityOption = ({
  faciOption,
  updateFacilityImageSoftDelete,
  facilitiesDataRefetchFunc,
}: facilityOptionType) => {
  const imageDate = new Date().toLocaleString('ja');
  const updateImageSoftDelete = async ({ facilityId, imageUrlKey }) => {
    await updateFacilityImageSoftDelete({
      facilityId: facilityId,
      imageUrlKey: imageUrlKey,
    });
  };
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const imageUrlComponent = useCallback(
    ({ imageUrl, imageUrlKey }: imageUrlComponentType) => {
      if (imageUrl) {
        return (
          <div
            key={imageUrl}
            style={{
              width: '33%',
              flexDirection: 'row',
              display: 'flex',
              marginRight: 5,
            }}>
            <AspectRatio ratio={0.75} style={{ maxWidth: '200px' }}>
              <img
                src={`${imageUrl}?${imageDate}`}
                width="100%"
                height={windowDimensions.width / 12.5}
                alt={imageUrl}></img>
            </AspectRatio>
            <div
              style={{
                boxShadow: 'none',
                position: 'absolute',
                transform: 'translate(-8px, -10px)',
              }}>
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                size="small"
                onClick={() =>
                  updateImageSoftDelete({
                    facilityId: faciOption.id,
                    imageUrlKey: imageUrlKey,
                  })
                }></Button>
            </div>
          </div>
        );
      } else return null;
    },
    [facilitiesDataRefetchFunc],
  );
  return (
    <div key={faciOption?.id}>
      <div style={{ fontSize: '1.9vmin' }}>{faciOption.name}</div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            marginRight: '50px',
            fontSize: '1.7vmin',
          }}>
          {faciOption.category}
        </div>
        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            fontSize: '1.7vmin',
          }}>
          {faciOption.subName}
        </div>
      </div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
        }}>
        {imageUrlComponent({
          imageUrl: faciOption?.imageUrl1,
          imageUrlKey: 'imageUrl1',
        })}
        {imageUrlComponent({
          imageUrl: faciOption?.imageUrl2,
          imageUrlKey: 'imageUrl2',
        })}
        {imageUrlComponent({
          imageUrl: faciOption?.imageUrl3,
          imageUrlKey: 'imageUrl3',
        })}
      </div>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}>
        <ImgDetail
          id={faciOption?.id}
          isPort={false}
          facilitiesDataRefetchFunc={facilitiesDataRefetchFunc}
        />
      </div>
      <div style={itemText}>説明</div>
      <div style={{ fontSize: '1.5vmin' }}>{faciOption.description}</div>
      <div style={itemText}>営業時間</div>
      <div>
        {faciOption?.weekTimes?.map((times) => (
          <li style={itemWeektime}>
            {times?.weekday} {times?.time}
          </li>
        ))}
      </div>
      <div style={itemText}>営業時間備考</div>
      <div style={{ fontSize: '1.5vmin' }}>{faciOption.openingHourNote}</div>
      <div style={itemText}>住所</div>
      <div style={{ fontSize: '1.5vmin' }}>{faciOption.zipCode}</div>
      <div style={{ fontSize: '1.5vmin' }}>{faciOption.address}</div>
      <div style={itemText}>設備</div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.washBasin ? (
            <img
              src={`${process.env.PUBLIC_URL}/wash.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/washGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={itemName}>洗面台</div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.milkWater ? (
            <img
              src={`${process.env.PUBLIC_URL}/water.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/waterGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={{ ...itemName, whiteSpace: 'pre-line' }}>
            {`調乳用の
            お湯`}
          </div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.microwave ? (
            <img
              src={`${process.env.PUBLIC_URL}/microwave.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/microwaveGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={{ ...itemName, whiteSpace: 'pre-line' }}>
            {`電子
            レンジ`}
          </div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.diapersPail ? (
            <img
              src={`${process.env.PUBLIC_URL}/trash.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/trashGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={{ ...itemName, whiteSpace: 'pre-line' }}>
            {`おむつ用
            ごみ箱`}
          </div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.babyChair ? (
            <img
              src={`${process.env.PUBLIC_URL}/seat.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/seatGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={{ ...itemName, whiteSpace: 'pre-line' }}>
            {`ベビー
            チェア`}
          </div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.weightScale ? (
            <img
              src={`${process.env.PUBLIC_URL}/scale.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/scaleGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={itemName}>体重計</div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.vendingMachine ? (
            <img
              src={`${process.env.PUBLIC_URL}/vending.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/vendingGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={itemName}>自販機</div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.privateRoom ? (
            <img
              src={`${process.env.PUBLIC_URL}/room.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/roomGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={{ ...itemName, whiteSpace: 'pre-line' }}>
            {`個室
            あり`}
          </div>
        </div>
        <div style={{ ...itemContener, flexDirection: 'column' }}>
          {faciOption.menOK ? (
            <img
              src={`${process.env.PUBLIC_URL}/menOk.svg`}
              style={{ width: '36px' }}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/menOkGray.svg`}
              style={{ width: '36px' }}
            />
          )}
          <div style={{ ...itemName, whiteSpace: 'pre-line' }}>
            {`男性
            可能`}
          </div>
        </div>
      </div>
      <div style={itemText}>設備備考</div>
      <div style={{ fontSize: '1.5vmin' }}>{faciOption.facilityNote}</div>
    </div>
  );
};
