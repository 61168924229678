import { Button, TextField } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';

registerLocale('ja', ja);
const inputStyle = {
  marginTop: '1px',
  marginBottom: '1em',
  backgroundColor: '#fff',
  display: 'flex',
  border: '1px solid rgb(0 0 0 / 0.1)',
  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  borderRadius: '3px',
  padding: '1px 2px',
};
const ButtonInputStyle = {
  marginTop: '1px',
  marginBottom: '1em',
  backgroundColor: '#fff',
  border: '1px solid rgb(0 0 0 / 0.1)',
  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  borderRadius: '3px',
  padding: '4.5px 4px',
};
const itemText = {
  color: '#000000d9',
  fontSize: '12px',
  fontWeight: '600'
};

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        border: '#dedede',
      },
      inputMarginDense: {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '0%',
      },
      colorPrimary: {
        color: '#FFFFFF',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '70px',
      },
    },
  },
});
type ReservationCsvModalType = {
  handleClose: () => void;
  setPriorityArray: React.Dispatch<
    React.SetStateAction<{
      item: any;
      total: number | null | undefined;
      __typename: string;
    }>
  >;
  reservationsData: any;
};
export const ReservationCsvModal = ({
  handleClose,
  setPriorityArray,
  reservationsData,
}: ReservationCsvModalType) => {
  const initialDate = moment().toDate();
  const [nameState, setNameState] = useState<string>('');
  const [couponState, setCouponState] = useState<string>('');
  const [phoneNoState, setPhoneNoState] = useState<string>('');
  const [startTimeState, setStartTimeState] = useState(initialDate);
  const [endTimeState, setEndTimeState] = useState(initialDate);
  const onChangeHandler = (e) => {
    if (e.target.name === 'name') {
      setNameState(e.target.value);
    } else if (e.target.name === 'coupon') {
      setCouponState(e.target.value);
    } else if (e.target.name === 'phoneNo') {
      setPhoneNoState(e.target.value);
    }
  };
  const onChangeStartDatePicker = (date) => {
    setStartTimeState(date);
  };
  const onChangeEndDatePicker = (date) => {
    setEndTimeState(date);
  };
  const allClear = () => {
    setNameState('');
    setCouponState('');
    setPhoneNoState('');
    setStartTimeState(initialDate);
    setEndTimeState(initialDate);
    setPriorityArray({
      item: [],
      total: reservationsData?.reservationsConnection?.total,
      __typename: 'ReservationConnection',
    });
    handleClose();
  };
  const stateSearchArr: string[] = [];
  const filterElement = () => {
    const nameStateFilterd = nameState
      ? reservationsData?.reservationsConnection?.item.filter((a: any) =>
        `${a.user.lastname}${a.user.firstname}`.includes(nameState),
        )
      : reservationsData?.reservationsConnection?.item;
    const couponStateFilterd = couponState
      ? reservationsData?.reservationsConnection?.item.filter((a: any) =>
          a?.issuedCoupon?.coupon?.name?.includes(couponState),
        )
      : reservationsData?.reservationsConnection?.item;
    const phoneNoStateFilterd = phoneNoState
      ? reservationsData?.reservationsConnection?.item.filter((a: any) =>
          a?.user?.phoneNo?.includes(phoneNoState),
        )
      : reservationsData?.reservationsConnection?.item;

    const timeStateFilterd =
      moment(startTimeState).format('YYYY-MM-DD') ===
      moment(endTimeState).format('YYYY-MM-DD')
        ? reservationsData?.reservationsConnection?.item?.filter(
            (a: any) =>
              moment(a?.startTime).format('YYYY-MM-DD') ===
              moment(startTimeState).format('YYYY-MM-DD'),
          )
        : reservationsData?.reservationsConnection?.item?.filter((a: any) =>
            moment(a?.startTime).isBetween(
              startTimeState,
              endTimeState,
              null,
              '[]',
            ),
          );

    if (!nameState && !couponState && !phoneNoState) {
      stateSearchArr?.push(...timeStateFilterd);
    } else {
      const nameCouponStateFilterd = nameStateFilterd?.filter((x: any) =>
        couponStateFilterd?.map((t: any) => t?.id).includes(x?.id),
      );
      const nameCouponPhoneNoStateFilterd = nameCouponStateFilterd?.filter(
        (x: any) => phoneNoStateFilterd?.map((t: any) => t?.id).includes(x?.id),
      );
      const allStateFilterd = nameCouponPhoneNoStateFilterd?.filter((x: any) =>
        timeStateFilterd?.map((t: any) => t?.id).includes(x?.id),
      );
      stateSearchArr.push(...allStateFilterd);
    }
    setPriorityArray({
      item: stateSearchArr,
      total: reservationsData?.reservationsConnection?.total,
      __typename: 'ReservationConnection',
    });
  };
  return (
    <ValidatorForm
      onSubmit={() => {
        filterElement();
      }}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            width: '30vmin',
            flexDirection: 'column',
          }}>
          <MuiThemeProvider theme={theme}>
            <div style={itemText}>氏名</div>
            <TextField
              style={{ ...inputStyle, width: '100%' }}
              value={nameState}
              onChange={onChangeHandler}
              name="name"
              id="filled-required"
              margin="dense"
              variant="outlined"
            />
          </MuiThemeProvider>

          <MuiThemeProvider theme={theme}>
            <div style={itemText}>クーポン</div>
            <TextField
              style={{ ...inputStyle, width: '100%' }}
              value={couponState}
              onChange={onChangeHandler}
              name="coupon"
              id="outlined-name"
              margin="dense"
              variant="outlined"
            />
          </MuiThemeProvider>
          <MuiThemeProvider theme={theme}>
            <div style={itemText}>電話番号</div>
            <TextField
              style={{ ...inputStyle, width: '100%' }}
              value={phoneNoState}
              onChange={onChangeHandler}
              name="phoneNo"
              id="outlined-name"
              margin="dense"
              variant="outlined"
            />
          </MuiThemeProvider>
        </div>
        <div
          style={{
            display: 'flex',
            width: '45vmin',
            flexDirection: 'column',
            marginLeft: 10,
          }}>
          <div style={itemText}>開始時間</div>
          <DatePicker
            style={{ ...inputStyle, width: '100%' }}
            locale="ja"
            selected={startTimeState}
            selectsStart
            startDate={moment(startTimeState).toDate()}
            endDate={moment(endTimeState).toDate()}
            onChange={onChangeStartDatePicker}
            customInput={
              <button style={ButtonInputStyle}>
                {moment(startTimeState).format('YYYY-MM-DD')}
              </button>
            }
          />
          <div style={itemText}>返却時間</div>
          <DatePicker
            style={{ ...inputStyle, width: '100%' }}
            locale="ja"
            selected={endTimeState}
            selectsEnd
            startDate={moment(startTimeState).toDate()}
            endDate={moment(endTimeState).toDate()}
            onChange={onChangeEndDatePicker}
            customInput={
              <button style={ButtonInputStyle}>
                {moment(endTimeState).format('YYYY-MM-DD')}
              </button>
            }
          />
        </div>
      </div>
      <Button
        style={{
          paddingTop: '3px',
          paddingBottom: '3px',
        }}
        onClick={() => {
          allClear();
        }}
        variant="outlined">
        クリア
      </Button>
      <Button
        style={{
          marginRight: '3%',
          marginLeft: 10,
          backgroundColor: '#12C670',
          borderColor: '#12C670',
          color: '#FFFFFF',
          padding: '3px',
        }}
        type="submit"
        variant="outlined">
        検索
      </Button>
    </ValidatorForm>
  );
};
