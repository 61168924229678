import { OutlinedInput, Select, TextField } from '@material-ui/core';

export const ModalLeft = ({
  values,
  handleChange,
  itemText,
  inputStyle,
}: any) => {
  const categoryOptions = [
    { label: '', value: '' },
    { label: '授乳室＆おむつ台', value: '授乳室＆おむつ台' },
    { label: '授乳室', value: '授乳室' },
    { label: 'おむつ台', value: 'おむつ台' },
    { label: 'エレベーター', value: 'エレベーター' },
    { label: '多目的トイレ', value: '多目的トイレ' },
    { label: 'キッズスペース＆公園', value: 'キッズスペース＆公園' },
  ];
  return (
    <>
      <div style={itemText}> 施設名*</div>
      <TextField
        style={{ ...inputStyle, width: '100%' }}
        required
        value={values.name}
        onChange={handleChange}
        name="name"
        id="filled-required"
        margin="dense"
        variant="outlined"></TextField>
      <div style={itemText}> 施設備考</div>
      <TextField
        style={{ ...inputStyle, width: '100%' }}
        value={values.subName}
        onChange={handleChange}
        name="subName"
        id="filled-required"
        margin="dense"
        variant="outlined"
      />
      <div style={itemText}> カテゴリー</div>
      <Select
        native
        value={values.category}
        onChange={handleChange}
        name={'category'}
        input={
          <OutlinedInput
            style={{ ...inputStyle, width: '100%', height: '32px' }}
            name={values.category}
            id="outlined-age-native-simple"
          />
        }>
        {categoryOptions.map((cateOption) => {
          return (
            <option key={cateOption.value} value={cateOption.value}>
              {cateOption.value}
            </option>
          );
        })}
      </Select>
      <div style={{ ...itemText }}> 説明</div>
      <TextField
        style={{ ...inputStyle, width: '100%' }}
        value={values.description}
        onChange={handleChange}
        name="description"
        id="filled-required"
        margin="dense"
        variant="outlined"
        multiline
        rows="4"
        inputProps={{ style: { fontSize: 12 } }}
      />
      <div style={itemText}> 住所*</div>
      <TextField
        style={{
          width: '45%',
          marginTop: '1px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          borderRadius: '3px',
        }}
        value={values.zipCode}
        onChange={handleChange}
        name="zipCode"
        id="filled-required"
        placeholder="000-0000"
        margin="dense"
        variant="outlined"
      />
      <TextField
        style={{ ...inputStyle, width: '100%' }}
        required
        value={values.address}
        onChange={handleChange}
        name="address"
        id="filled-required"
        margin="dense"
        variant="outlined"
        multiline
        rows="3"
        inputProps={{ style: { fontSize: 12 } }}
      />
      <div style={itemText}> GPS座標*</div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          style={{
            width: '48%',
            marginTop: '1px',
            marginRight: '4%',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            borderRadius: '3px',
          }}
          required
          value={values.longitude}
          onChange={handleChange}
          name="longitude"
          id="filled-required"
          placeholder="経度"
          margin="dense"
          type="number"
          variant="outlined"
          inputProps={{ style: { fontSize: 14 } }}
        />
        <TextField
          style={{
            width: '48%',
            marginTop: '1px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            borderRadius: '3px',
          }}
          required
          value={values.latitude}
          onChange={handleChange}
          name="latitude"
          id="filled-required"
          placeholder="緯度"
          margin="dense"
          type="number"
          variant="outlined"
          inputProps={{ style: { fontSize: 14 } }}
        />
      </div>
    </>
  );
};
