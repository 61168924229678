import {useEffect, useState} from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { MySnackBar } from '@src/components/MySnackBar';
import { showFail } from '@src/components/Alert';
import {useApolloClient} from '@apollo/client';

const SignIn = (props: any): JSX.Element => {
  const styles = makeStyles((theme: Theme) =>
    createStyles({
      main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
          3,
        )}px`,
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        marginTop: theme.spacing(3),
      },
      margin: {},
    }),
  );
  const value: any = {
    email: '',
    password: '',
    error: null,
  };

  const [loginValue, setLoginValue] = useState(value);
  const apolloClient = useApolloClient();

  useEffect(() => {
    apolloClient.resetStore();
  }, []);
  const onChangeHandler = (e) => {
    setLoginValue({ ...loginValue, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let response: any = null;
    try {
      response = await props.login({
        variables: {
          email: loginValue.email,
          password: loginValue.password,
          firebaseToken: localStorage.getItem('firebaseToken')
        },
      });
    } catch (err) {
      console.error('err', err);
      showFail({ description: err.message });
    }
    props.refreshTokenFn({ token: response?.data?.adminLogin?.token });
    props.history.push('/');
  };

  const classes = styles();
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper elevation={2} className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => onChangeHandler(e)}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => onChangeHandler(e)}
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}>
            Sign in
          </Button>
        </form>
        {loginValue.error ? (
          <div>
            <Grid>
              <MySnackBar
                variant="error"
                className={classes.margin}
                message={loginValue.error}
              />
            </Grid>
          </div>
        ) : null}
      </Paper>
    </main>
  );
};

const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!,$firebaseToken: String) {
    adminLogin(email: $email, password: $password, firebaseToken: $firebaseToken) {
      token
    }
  }
`;
export const LoginComponent = graphql(LOGIN, { name: 'login' })(SignIn);
