import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { facilitiesQuery_facilities } from '@src/generated/graphql';
import { useFormikContext } from 'formik';
import { createRef, RefObject, useRef, useState } from 'react';
import { FacilityWeekTimes } from './FacilityWeekTimes';

type weekTimesObjType = {
  [x: number]: any;
  id?: string;
  weekday?: string;
  time?: string;
};
const weeklyInitData = [
  { label: 'weekday0', value: '' },
  { label: 'weekday1', value: '月曜日' },
  { label: 'weekday2', value: '火曜日' },
  { label: 'weekday3', value: '水曜日' },
  { label: 'weekday4', value: '木曜日' },
  { label: 'weekday5', value: '金曜日' },
  { label: 'weekday6', value: '土曜日' },
  { label: 'weekday7', value: '日曜日' },
  { label: 'weekday8', value: '祝日' },
];
const inputErrorInitData = {
  weekday0: false,
  weekday1: false,
  weekday2: false,
  weekday3: false,
  weekday4: false,
  weekday5: false,
  weekday6: false,
};

//const inputErrorInitData2 = (id: string) => [{ [id]: false }];
export const ModalCenter = ({
  values,
  handleChange,
  theme,
  itemText,
  inputStyle,
  itemFacility,
}: any) => {
  const weekTimeIntObj = () => {
    if (values?.weekTimes) {
      const valuesRemains = values?.weekTimes?.map((weekTime, index) => {
        if (weekTime && weekTime?.id) {
          const { __typename, id, ...remain } = weekTime as any;
          remain.id = `weekday${index}`;
          return remain;
        } else return [];
      });
      return valuesRemains;
    } else return [];
  };
  const weekTimeIntObjCheck = values?.weekTimes ? weekTimeIntObj() : [];
  const textInputRef = useRef<RefObject<HTMLInputElement>[]>([]);
  const weekTimesRef = useRef<weekTimesObjType[]>(weekTimeIntObjCheck);
  const { setValues } = useFormikContext<facilitiesQuery_facilities>();
  const [inputError, setInputError] = useState(inputErrorInitData);
  const valuesWeekTimesLength = values?.weekTimes
    ? values?.weekTimes?.length
    : 0;
  const weekTimesHandleChange = (e) => {
    if (textInputRef.current) {
      const textInputRefCurrent: any = textInputRef.current;
      for (const phrase of textInputRefCurrent) {
        if (!phrase.current.validity.valid) {
          inputErrorInitData[phrase.current.id] = true;
          setInputError(inputErrorInitData);
        } else {
          inputErrorInitData[phrase.current.id] = false;
          setInputError(inputErrorInitData);
        }
      }
    }
    const weekTimesObj = [
      {
        [e.target.name]: e.target.value,
        id: e.target.id,
      },
    ];
    const findedRefWeekday = weekTimesRef.current?.find(
      (week) => week?.id === e.target.id,
    );
    if (findedRefWeekday) {
      if (e.target.name === 'time') {
        const weeklyFix = weekTimesRef.current.map((def: any) =>
          def.id === e.target.id
            ? { id: def.id, weekday: def.weekday, time: e.target.value }
            : def,
        );
        weekTimesRef.current = weeklyFix;
      } else if (e.target.name === 'weekday') {
        const timesFix = weekTimesRef.current.map((def: any) =>
          def.id === e.target.id
            ? { id: def.id, weekday: e.target.value, time: def.time }
            : def,
        );
        weekTimesRef.current = timesFix;
      }
    } else weekTimesRef.current.push(...weekTimesObj);
    setValues({
      ...values,
      weekTimes: weekTimesRef.current,
    });
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <div style={{ ...itemText }}> 駅名*</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '40px',
          }}>
          <TextField
            style={{ ...inputStyle, width: '19vmin', marginRight: '4%' }}
            required
            value={values.station}
            onChange={handleChange}
            name="station"
            id="filled-required"
            margin="dense"
            variant="outlined"
          />
          <FormControlLabel
            style={{ ...itemFacility, width: '16vmin' }}
            labelPlacement="start"
            control={
              <Switch
                name="openYearRound"
                disableRipple
                checked={values.openYearRound}
                onChange={handleChange}
                value="true"
              />
            }
            label="年中無休"
          />
        </div>
      </MuiThemeProvider>
      {!values?.openYearRound ? (
        <div
          style={{
            marginBottom: '0.5em',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div>
            <div style={{ ...itemText, width: '13vmin' }}>営業時間</div>
          </div>
          {[...Array(valuesWeekTimesLength + 1)].map((_, index) => {
            textInputRef.current[index] = createRef<HTMLInputElement>();
            return (
              <FacilityWeekTimes
                key={index}
                textInputRef={textInputRef}
                inputError={inputError}
                weekTimesHandleChange={weekTimesHandleChange}
                weeklyInitData={weeklyInitData}
                count={index}
                values={values}
              />
            );
          })}
        </div>
      ) : null}
      <MuiThemeProvider theme={theme}>
        <div style={itemText}> 営業時間備考</div>
        <TextField
          style={{ ...inputStyle, width: '100%' }}
          value={values.openingHourNote}
          onChange={handleChange}
          name="openingHourNote"
          id="filled-required"
          margin="dense"
          variant="outlined"
          multiline
          rows="2"
          inputProps={{ style: { fontSize: 12 } }}
        />
      </MuiThemeProvider>
    </>
  );
};
