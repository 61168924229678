import { FormControlLabel, Switch, TextField } from '@material-ui/core';

export const ModalRight = ({
  values,
  handleChange,
  itemText,
  itemFacility,
  inputStyle,
}: any) => {
  return (
    <>
      <div style={itemText}> 施設</div>
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            name="washBasin"
            disableRipple
            checked={values.washBasin}
            onChange={handleChange}
            value="true"
          />
        }
        label="洗面台"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            name="milkWater"
            disableRipple
            checked={values.milkWater}
            onChange={handleChange}
            value="true"
          />
        }
        label="調乳用のお湯"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            name="microwave"
            disableRipple
            checked={values.microwave}
            onChange={handleChange}
            value="true"
          />
        }
        label="電子レンジ"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            name="diapersPail"
            disableRipple
            checked={values.diapersPail}
            onChange={handleChange}
            value="true"
          />
        }
        label="おむつ用ゴミ箱"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            name="babyChair"
            disableRipple
            checked={values.babyChair}
            onChange={handleChange}
            value="true"
          />
        }
        label="ベビーチェア"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            style={{}}
            name="weightScale"
            disableRipple
            checked={values.weightScale}
            onChange={handleChange}
            value="true"
          />
        }
        label="体重計"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            style={{}}
            name="vendingMachine"
            disableRipple
            checked={values.vendingMachine}
            onChange={handleChange}
            value="true"
          />
        }
        label="自販機"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            style={{}}
            name="privateRoom"
            disableRipple
            checked={values.privateRoom}
            onChange={handleChange}
            value="true"
          />
        }
        label="個室あり"
      />
      <FormControlLabel
        style={itemFacility}
        labelPlacement="start"
        control={
          <Switch
            style={{}}
            name="menOK"
            disableRipple
            checked={values.menOK}
            onChange={handleChange}
            value="true"
          />
        }
        label="男性可能"
      />
      <div style={itemText}> 設備備考</div>
      <TextField
        style={{ ...inputStyle, width: '100%' }}
        value={values.facilityNote}
        onChange={handleChange}
        name="facilityNote"
        id="filled-required"
        margin="dense"
        variant="outlined"
      />
    </>
  );
};
