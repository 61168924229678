import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export const AddDoorToPortModal = ({
  portId,
  addDoorToPort,
  addDoorToPortCancel,
}: any) => (
  <Dialog
    open={!!portId}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">
      このポートにドアを追加しますか？
    </DialogTitle>
    <DialogActions>
      <Button
        onClick={async () => addDoorToPort(portId)}
        color="primary"
        autoFocus>
        Yes
      </Button>
      <Button onClick={() => addDoorToPortCancel()} color="primary">
        No
      </Button>
    </DialogActions>
  </Dialog>
);
