import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Table} from "antd";
import moment from 'moment-timezone';
moment.tz.setDefault('Asia/Tokyo');
import './ReservationPaymentInfoModal.css';
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        minWidth: 800,
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    icon: {
        cursor: 'pointer',
    },
    title: {
        fontWeight: 600,
    }
}));

const reservationPaymentHistoriesQuery = gql`
  query ReservationPaymentHistoriesQuery($reservationId: String!) {
    paymentHistories(reservationId: $reservationId) {
      id
      amount
      status
      dateTime
    }
  }
`;

const getPaidStatusText = (status: string) => {
    switch (status) {
        case 'FAILED':
            return '決済失敗';
        case 'SUCCEEDED':
            return '支払済';
        default:
            return '未知'
    }
}

export default function ReservationPaymentInfoModal({ reservationId,isHaveFailPaid }: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dataSource, setDataSource] = React.useState<any[]>([]);
    const [findChatById, {
        data,
        loading,
        error
    }] = useLazyQuery<any>(reservationPaymentHistoriesQuery, {
        fetchPolicy: 'network-only',
    });

    const handleOpen = () => {
        setOpen(true);
        if (reservationId) {
            findChatById({
                variables: { reservationId: reservationId }
            })
        }
    };

    useEffect(() => {
        if (!loading && error === undefined){
            if (data?.paymentHistories) {
                setDataSource(data?.paymentHistories);
            }
        }
    }, [loading]);

    const handleClose = () => {
        setOpen(false);
        setDataSource([])
    };

    const columns = [
        {
            title: '日付',
            key: 'dateTime',
            render: (record:any) => {
                return <div>
                    {moment(record?.dateTime).format('YYYY/MM/DD HH:mm:ss')}
                </div>;
            },
        },
        {
            title: '金額',
            key: 'amount',
            render: (record:any) => {
                return <div>
                    {record?.amount}円
                </div>;
            },
        },
        {
            title: '状況',
            key: 'status',
            // sorter: (a,b) => {
            //     return a.status.localeCompare(b.status)
            // },
            render: (record:any) => {
                return <div>
                    {getPaidStatusText(record?.status)}
                </div>;
            },
        },
    ];

    return (
        <div>
            <div onClick={handleOpen}>
                {
                    isHaveFailPaid ? <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" height="20px"
                                          viewBox="0 -960 960 960" width="20px"
                                          fill="#EE4E4E">
                        <path
                            d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    </svg> : <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" height="20px"
                                  viewBox="0 -960 960 960" width="20px"
                                  fill="#40A578">
                        <path
                            d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    </svg>
                }
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <p className={classes.title}>決済状況</p>
                        <Table
                            loading={loading}
                            size="small"
                            bordered={true}
                            pagination={dataSource.length > 10 ? {} : false}
                            dataSource={dataSource} columns={columns}
                            className={'reservation-payment-table-wrapper'}
                            rowKey={(record) => record.id}
                        />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
