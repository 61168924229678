import gql from 'graphql-tag';

export const SEND_MESSAGE_MUTATION = gql`
  mutation SEND_MESSAGE_IMAGE(
    $chatId: ID!
    $text: String
    $helpQuestion: HelpQuestion
    $messageType: MessageType!
    $imageKey: String
    $imageBucket: String
  ) {
    sendMessage(
      chatId: $chatId
      text: $text
      helpQuestion: $helpQuestion
      messageType: $messageType
      imageKey: $imageKey
      imageBucket: $imageBucket
    ) {
      id
    }
  }
`;
export const SEND_PUBLIC_MESSAGE_MUTATION = gql`
  mutation SEND_PUBLIC_MESSAGE(
    $email: String!
    $text: String!
    $helpQuestion: HelpQuestion
    $messageType: MessageType!
    $imageKey: String
    $imageBucket: String
  ) {
    sendPublicMessage(
      email: $email
      text: $text
      isAdminMessage: true
      helpQuestion: $helpQuestion
      messageType: $messageType
      imageKey: $imageKey
      imageBucket: $imageBucket
    ) {
      id
    }
  }
`;
export const UPDATE_FACILITY_IMAGE = gql`
  mutation updateFacilityImage($facilityId: ID!, $data: FacilityUpdateInput) {
    updateFacility(facilityId: $facilityId, data: $data) {
      id
    }
  }
`;

export const UPDATE_PORT_IMAGE = gql`
  mutation updatePortImage($portId: ID!, $data: PortUpdateInput) {
    updatePort(portId: $portId, data: $data) {
      id
    }
  }
`;
