// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 678px) {
  .ant-card-body {
    padding: 0 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/style.less"],"names":[],"mappings":"AAGA;EACE;IACE,qBAAA;EAFF;AACF","sourcesContent":[".ant-card-body {\n}\n\n@media (max-width: 678px) {\n  .ant-card-body {\n    padding: 0 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
