import { Button } from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { useS3Image } from '@src/hooks/useS3Image';
import Resizer from 'react-image-file-resizer';
import useWindowDimensions from "@src/hooks/useWindowDimensions";

type uploadS3FacilityImageFuncType = {
  img: any;
  imageUrlKey: string;
};

type ImgDetailType = {
  id: string;
  isPort: boolean;
  facilitiesDataRefetchFunc?: () => Promise<void>;
};
export const ImgDetail = ({
  id,
  isPort,
  facilitiesDataRefetchFunc,
}: ImgDetailType) => {
  const { uploadS3FacilityImage, uploadS3PortImage } = useS3Image();
  const { width } = useWindowDimensions();

  const resizeFile = (file: Blob): Promise<string> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        'JPEG',
        50,
        0,
        (uri) => {
          resolve(uri as string);
        },
        'base64',
      );
    });
  };
  const onChangeImageUrl1 = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      const img = document.createElement('img');
      img.src = image as string;
      if (isPort) {
        uploadS3PortImageFunc({ img: img, imageUrlKey: 'imageUrl1' });
      } else uploadS3FacilityImageFunc({ img: img, imageUrlKey: 'imageUrl1' });
    } catch (err) {
      console.error(err);
    }
  };
  const onChangeImageUrl2 = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      const img = document.createElement('img');
      img.src = image as string;
      if (isPort) {
        uploadS3PortImageFunc({ img: img, imageUrlKey: 'imageUrl2' });
      } else uploadS3FacilityImageFunc({ img: img, imageUrlKey: 'imageUrl2' });
    } catch (err) {
      console.error(err);
    }
  };
  const onChangeImageUrl3 = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      const img = document.createElement('img');
      img.src = image as string;
      if (isPort) {
        uploadS3PortImageFunc({ img: img, imageUrlKey: 'imageUrl3' });
      } else uploadS3FacilityImageFunc({ img: img, imageUrlKey: 'imageUrl3' });
    } catch (err) {
      console.error(err);
    }
  };

  const uploadS3FacilityImageFunc = ({
    img,
    imageUrlKey,
  }: uploadS3FacilityImageFuncType) => {
    try {
      uploadS3FacilityImage({
        uri: img.src,
        facilityId: id,
        imageUrlKey: imageUrlKey,
      });
    } catch (e) {
      console.log(e, 'error');
    } finally {
      if (facilitiesDataRefetchFunc) {
        facilitiesDataRefetchFunc();
      }
    }
  };
  const uploadS3PortImageFunc = ({
    img,
    imageUrlKey,
  }: uploadS3FacilityImageFuncType) => {
    uploadS3PortImage({
      uri: img.src,
      portId: id,
      imageUrlKey: imageUrlKey,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: width < 400 ? 'column' : 'row',
        gap: '10px',
        padding: '0 20px',
        color: '#FFF'
      }}>
      <Button
        variant="contained"
        component="label"
        size="medium"
        color="inherit"
        style={{
          height: '36px',
          boxShadow: 'none',
          backgroundColor: '#FF979A',
          whiteSpace: 'nowrap',
          fontSize: width < 700 ? '11px' : ''
        }}
        startIcon={<AddToPhotosIcon style={{ fontSize: width < 700 ? '16px' : '' }} />}>
        <input type="file" onChange={onChangeImageUrl1} hidden />
        画像①
      </Button>
      <Button
        variant="contained"
        component="label"
        size="medium"
        color="inherit"
        style={{
          height: '36px',
          boxShadow: 'none',
          backgroundColor: '#FF979A',
          whiteSpace: 'nowrap',
          fontSize: width < 700 ? '11px' : ''
        }}
        startIcon={<AddToPhotosIcon style={{ fontSize: width < 700 ? '16px' : '' }}/>}>
        <input type="file" onChange={onChangeImageUrl2} hidden />
        画像②
      </Button>
      <Button
        variant="contained"
        component="label"
        size="medium"
        color="inherit"
        style={{
          height: '36px',
          boxShadow: 'none',
          backgroundColor: '#FF979A',
          whiteSpace: 'nowrap',
          fontSize: width < 700 ? '11px' : ''
        }}
        startIcon={<AddToPhotosIcon style={{ fontSize: width < 700 ? '16px' : '' }} />}>
        <input type="file" onChange={onChangeImageUrl3} hidden />
        画像③
      </Button>
    </div>
  );
};
