import { initializeApp } from '@firebase/app';
import {getMessaging, getToken,isSupported} from '@firebase/messaging';

const firebaseInitConfig = {
    apiKey : "AIzaSyAUI5K1goQZeQncYNgb9VkS0JWzFYS3fGQ" ,
    authDomain : "sharebuggy-test2.firebaseapp.com" ,
    projectId : "sharebuggy-test2" ,
    storageBucket : "sharebuggy-test2.appspot.com" ,
    messagingSenderId : "925447922388" ,
    appId : "1:925447922388:web:4fde4f1a8de601dc66d141" ,
    measurementId : "G-3K0LZK3TTR"
}
const app = initializeApp(firebaseInitConfig)
const messaging = await isSupported() ? getMessaging(app) : null;

export const firebaseCheckSupported = async () =>{
    return await isSupported();
}

export const requestPermissions = async () => {
    // @ts-ignore
    Notification.requestPermission().then((permission) =>{
        if (messaging) {
            if (permission === 'granted') {
                return getToken(messaging).then(currentToken => {
                    if (currentToken) {
                        localStorage.setItem('firebaseToken',currentToken)
                        console.log(currentToken)
                    }else {
                        console.log('Can not register firebaseToken')
                        localStorage.removeItem('firebaseToken')
                    }
                }).catch(error => {
                    console.log(error)
                })
            }else {
                console.log('User permissions is denied')
            }
        }
    })
}