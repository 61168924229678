// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rce-container-mbox {
  margin-bottom: 10px;
  min-width: 200px;
}
.rce-container-mbox .rce-mbox.rce-mbox-right,
.rce-container-mbox .rce-mbox {
  max-width: 51%;
}
@media (max-width: 678px) {
  .rce-container-mbox .rce-mbox.rce-mbox-right,
  .rce-container-mbox .rce-mbox {
    max-width: 60%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PublicChats/SingleChat/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gBAAA;AACF;AACA;;EACE,cAAA;AAEF;AAAA;EACE;;IACE,cAAA;EAGF;AACF","sourcesContent":[".rce-container-mbox {\n  margin-bottom: 10px;\n  min-width: 200px;\n}\n.rce-container-mbox .rce-mbox.rce-mbox-right, .rce-container-mbox .rce-mbox {\n  max-width: 51%;\n}\n@media (max-width: 678px) {\n  .rce-container-mbox .rce-mbox.rce-mbox-right, .rce-container-mbox .rce-mbox {\n    max-width: 60%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
