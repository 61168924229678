import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {
  ForcedUnlockButton,
  ReservationButton,
  PaymentStatusButton,
} from './reservationButton';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Chats } from '@src/pages/Chats';
import ReservationPaymentInfoModal from "@src/pages/Reservations/ReservationPaymentInfoModal";
import {Box} from "@material-ui/core";
moment.tz.setDefault('Asia/Tokyo');
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export const ReservationRow = ({
  reservation = {
    port: {},
    door: {},
    babyCar: {},
    issuedCoupon: { coupon: {} },
  },
  index = 0,
  unlock,
  adminLock,
  finish,
  updateReservation,
  isSystemAdmin,
  adminLockReservation,
  createChat,
}: any) => {
  const history = useHistory();
  const [popoverOpen, setpopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const anchorEL = document.getElementById(reservation.id);
  const userChatID = reservation.user.chats.map((chat) => chat.id);
  const selectUserFn = async () => {
      if (!reservation.user.chats || reservation.user.chats.length === 0) {
          const {data} = await createChat(reservation.user.id);
          const {createChat: chat} = data;
          if (!chat) {
              return;
          }
          history.push(`/chats/${chat.id}`);
          return;
      }
      history.push(`/chats/${userChatID}`);
  };
  // const mouseOverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
  //   const target: HTMLDivElement = event.currentTarget;
  //   target.style.textDecoration = 'underline';
  // };
  // const mouseOutHandler = (event: React.MouseEvent<HTMLDivElement>) => {
  //   const target: HTMLDivElement = event.currentTarget;
  //   target.style.textDecoration = 'none';
  // };
    const [paidFailMsg, setPaidFailMsg] = useState('');
    useEffect(() => {
        if(reservation.paymentHistories.length > 0){
            for (let i = 0; i < reservation.paymentHistories.length; i++) {
                if (reservation.paymentHistories[i].status === 'FAILED') {
                    setPaidFailMsg('部分決済失敗')
                    break;
                }
            }
        }
    }, [reservation.id]);

  const handleFormatPriceJapan = (price: string) => {
      // @ts-ignore
      return  price.toLocaleString('en-US');
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoCustomerPage = (name: string) => {
    history.push('/customers',{params: name})
  }

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: '1rem',
    },
  }))(Tooltip);
  return (
    <TableRow key={reservation.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {reservation.user.deletedAt ? (
          `${reservation?.user?.lastname}${reservation?.user?.firstname}`
        ) : (
          <>
            <Button aria-describedby={Boolean(anchorEl) ? 'simple-popover' : undefined} onClick={handleClick}>
              {`${reservation?.user?.lastname}${reservation?.user?.firstname}`}
            </Button>
            <Popover
              id={Boolean(anchorEl) ? 'simple-popover' : undefined}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }} width={100}>
                <Button onClick={() => handleGoCustomerPage(`${reservation?.user?.firstname}${reservation?.user?.lastname}`)}>
                  詳細
                </Button>
                <Button
                  onClick={async () => !reservation.user.deletedAt && selectUserFn()}>
                  チャット
                </Button>
              </Box>
            </Popover>
          </>
        )}
        <Switch>
          <Route exact path="/chats/:userChatID">
            <Chats reservation={reservation} />
          </Route>
        </Switch>
      </TableCell>
      <TableCell style={{
          maxWidth: 200,
      }}>
        <LightTooltip title={reservation?.startPort?.name} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation?.startPort?.name}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation?.startDoor?.doorIndex} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation?.startDoor?.doorIndex}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell style={{
          maxWidth: 200,
      }}>
        <LightTooltip title={reservation?.port?.name} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation?.port?.name}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation?.door?.doorIndex} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation?.door?.doorIndex}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <ReservationButton
          reservation={reservation}
          unlock={unlock}
          adminLock={adminLock}
        />
      </TableCell>
      <TableCell style={{ color: reservation.status ? 'green' : 'red' }}>
        {reservation.status ? '返却済' : '貸出中'}
      </TableCell>
      <TableCell>
        <ForcedUnlockButton
          reservation={reservation}
          adminLockReservation={adminLockReservation}
        />
      </TableCell>
      <TableCell style={{
          maxWidth: 130,
      }}>
        <LightTooltip title={`${handleFormatPriceJapan(reservation.issuedCoupon
          ? reservation.taxedAmount + reservation.issuedCoupon.coupon.discountAmount :
          reservation.taxedAmount)} 円`} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {handleFormatPriceJapan(reservation.issuedCoupon
              ? reservation.taxedAmount + reservation.issuedCoupon.coupon.discountAmount :
              reservation.taxedAmount)
            }
            円
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell
        id={reservation.id}
        style={{
          maxWidth: 200,
        }}>
        <LightTooltip title= {reservation.issuedCoupon ? reservation.issuedCoupon.coupon.name : ''} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            aria-owns={popoverOpen ? 'simple-popper' : undefined}
            aria-haspopup="true"
            onClick={() => {
              setpopoverOpen(true);
            }}>
            {reservation.issuedCoupon ? reservation.issuedCoupon.coupon.name : ''}
          </Typography>
        </LightTooltip>
        <Popover
          anchorEl={anchorEL}
          open={popoverOpen}
          onClose={() => {
            setpopoverOpen(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Typography>
            {reservation.issuedCoupon
              ? reservation.issuedCoupon.coupon.name
              : ''}
          </Typography>
        </Popover>
      </TableCell>
      <TableCell>
        <PaymentStatusButton
          paidFailMsg={paidFailMsg}
          reservation={reservation}
          updateReservation={updateReservation}
        />
      </TableCell>
      <TableCell
          style={{ color: reservation.issuedCoupon ? 'red' : '',maxWidth: 150, }}>
        <LightTooltip title={`${reservation.issuedCoupon
          ? reservation.taxedAmount
          : reservation.taxedAmount} 円`}
                       placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}> {reservation.issuedCoupon
            ? reservation.taxedAmount
            : reservation.taxedAmount}
            円
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell style={{
          maxWidth: 200,
      }}>
        <LightTooltip title={moment(reservation.startTime).format('YYYY/MM/DD HH:mm:ss')} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {moment(reservation.startTime).format('YYYY/MM/DD HH:mm:ss')}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell style={{
          maxWidth: 200,
      }}>
        <LightTooltip title={reservation.returnTime ? moment(reservation.returnTime).format('YYYY/MM/DD HH:mm:ss') : ''} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation.returnTime
              ? moment(reservation.returnTime).format('YYYY/MM/DD HH:mm:ss')
              : null}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation.user.phoneNo ? reservation.user.phoneNo : ''} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation.user.phoneNo ? reservation.user.phoneNo : ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation.product.name ? reservation.product.name : ''} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation.product.name ? reservation.product.name : ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation.extendedTimes >= 0 ? reservation.extendedTimes : ''} placement="bottom">
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {reservation.extendedTimes >= 0 ? reservation.extendedTimes : ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <ReservationPaymentInfoModal isHaveFailPaid={paidFailMsg !== ''} reservationId={reservation.id} />
      </TableCell>
        <TableCell>
            {isSystemAdmin && !reservation.status ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => finish(reservation.id)}>
                    FINISH
                </Button>
            ) : null}
        </TableCell>
    </TableRow>
  );
};
