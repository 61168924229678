import { useEffect, useRef, useState } from 'react';
import AWS from 'aws-sdk';
import moment from 'moment';
import { Form, Input, Button, message, Upload } from 'antd';
import { FetchResult } from '@apollo/client';
import { MessageType } from '@src/generated/graphql';
import {
  MessageList,
  MessageType as elementsMessageType,
} from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import {SendOutlined, UploadOutlined} from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { useS3Image } from '@src/hooks/useS3Image';
import { Buffer } from 'buffer';
import imageCompression from 'browser-image-compression';
import {makeStyles} from "@material-ui/core/styles";
import './style.less';
const { TextArea } = Input;

type publicChatImageType = {
  key: string | null | undefined;
  imageDataSource: string;
};
export type SingleChatProps = {
  messages: {
    id: any;
    text: string | null;
    createdAt: string;
    isAdminMessage: boolean | null;
    imageContent?: {
      imageKey: string | null;
      imageBucket: string | null;
    } | null;
  }[];
  email: string;
  receiveMessage?: any;
  sendMessage: (v: {
    variables: { email: string; text: string | null; messageType: string };
  }) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
};
const onFinishFailed = (errorInfo: any): void => {
  console.log('Failed:', errorInfo);
};

const useStyles = makeStyles(() => {
  return {
    messageList: {
      // border: '1px solid #FF979A',
      // marginTop: '5px',
      padding: '15px 5px',
      width: '100%',
      // height: '100px',
      flex: '1 1 auto',
      overflow: 'auto',
      flexDirection: 'row',
      backgroundColor: '#f4f4fa',
    }
  }
})

export const SinglePublicChatComponent = ({
  messages,
  email,
  sendMessage,
}: SingleChatProps): JSX.Element => {
  const { uploadS3Image } = useS3Image();
  const s3 = new AWS.S3();
  const [form] = Form.useForm();
  const [publicChatImageArr, setPublicChatImageArr] = useState<
    publicChatImageType[] | null
  >([]);
  const messageListReferance = useRef<any>(null);
  const classes = useStyles();
  useEffect(() => {
    const scroll =
      messageListReferance?.current?.scrollHeight -
      messageListReferance?.current?.clientHeight;
    messageListReferance?.current?.scrollTo(0, scroll);
  }, [messageListReferance]);

  const onSend = async (e) => {
    const { message } = e;
    await sendMessage({
      variables: {
        email,
        text: message,
        messageType: MessageType.TEXT,
      },
    });
    form.resetFields();
    localStorage.removeItem(`${email}_messageKey`);
  };

  const customerAvatarUrl =
    'https://s3-ap-northeast-1.amazonaws.com/share-buggy/profilePicture.png';

  const imageProps: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      return new Promise(() => {
        const reader = new FileReader();
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 520,
          useWebWorker: true,
        };
        imageCompression(file, options).then((compressedFile) => {
          reader.readAsDataURL(compressedFile);
          reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result as string;
            uploadS3Image({ uri: img.src, meId: email, isPublic: true });
          };
        });
      });
    },
  };

  let messagesImageArr: publicChatImageType[] = [];
  useEffect(() => {
    messages.map((m) => {
      let base64ImageDataSource: string = '';
      if (m.imageContent?.imageKey && m.imageContent?.imageBucket) {
        const params = {
          Key: m.imageContent?.imageKey,
          Bucket: m.imageContent?.imageBucket,
        };
        s3.getObject(params, (err, data) => {
          let imageData: AWS.S3.GetObjectOutput | undefined = {};

          if (err) {
            console.log('Err: getObject failed :' + err);
          } else {
            imageData = data;
            const buffer = Buffer.from(imageData.Body as any);
            const base64ImageData = buffer.toString('base64');
            base64ImageDataSource = 'data:image/png;base64,' + base64ImageData;
            messagesImageArr.push({
              key: m.imageContent?.imageKey,
              imageDataSource: base64ImageDataSource,
            });
            if (publicChatImageArr) {
              setPublicChatImageArr([
                ...publicChatImageArr,
                ...messagesImageArr,
              ]);
            } else {
              setPublicChatImageArr(messagesImageArr);
            }
          }
        });
      }
    });
  }, [messages]);

  const formattedMessages = () => {
    return messages.map((m, i) => {
      const imageUrl = publicChatImageArr?.find(
        (img) => img.key === m.imageContent?.imageKey,
      );
      const dateString = moment(m.createdAt).format('YYYY/MM/DD HH:mm');
      return {
        id: i,
        position: m.isAdminMessage ? 'right' : 'left',
        title: m.isAdminMessage ? 'Admin' : 'Customer',
        titleColor: m.isAdminMessage ? '#191970' : '#FF979A',
        text: m.text,
        type: m.text ? 'text' : 'photo',
        avatar: m.isAdminMessage ? undefined : customerAvatarUrl,
        date: new Date(),
        dateString: dateString,
        data: {
          uri: imageUrl?.imageDataSource,
          width: 256,
          height: 256,
        },
      };
    });
  };

  return (
    <>
      <MessageList
        referance={messageListReferance}
        downButton={true}
        sendMessagePreview={false}
        downButtonBadge={0}
        className={classes.messageList}
        lockable={false}
        toBottomHeight={'100%'}
        dataSource={formattedMessages() as elementsMessageType[]}
      />
      <div style={{
        flexDirection: 'row',
        padding: '0 10px',
        backgroundColor: '#f4f4fa',
        marginTop: 'auto',
        flex: '0 0 auto'
      }}>
        <Form
          onFieldsChange={(value) => {
            localStorage[`${email}_messageKey`] = value[0].value;
          }}
          name="basic"
          initialValues={{
            message: localStorage.getItem(`${email}_messageKey`) || undefined,
          }}
          onFinish={onSend}
          form={form}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            name="message"
            style={{ margin: 0, backgroundColor: '#FFF', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
            rules={[{required: true, message: 'Please input your message!'}]}>
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} style={{ border: 'none', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', outline: 'none', boxShadow: 'none', padding: '6px 12px' }} placeholder="Message..."/>
          </Form.Item>
          <Form.Item style={{ backgroundColor: '#FFF', padding: '10px', marginBottom: '10px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Upload {...imageProps}>
                <Button style={{ border: 'none' }}
                        icon={<UploadOutlined style={{ fontSize: '22px', color: '#6b7280' }}/>}>
                </Button>
              </Upload>
              <Button
                type="primary"
                htmlType="submit"
                style={{borderRadius: '4px', fontSize: '15px', backgroundColor: '#fa4e69'}} icon={<SendOutlined />}>
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
