import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomersComponent } from '@src/components/Customers/CustomersList';
import {useMutation} from "@apollo/client";
import {MySnackBar} from "@src/components/MySnackBar";

const resendVerifyEmail = gql`
  mutation ResendVerifyEmail($email: String!) {
    resendConfirmEmail(email: $email)
  }
`;

const CustomersComponent1 = (props: any) => {
  const {
    data: {
      users,
      loading,
      error,
      refetch: customersQueryRefetch
    },
  } = props;

  const [setResendVerifyEmail] = useMutation(resendVerifyEmail);

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <MySnackBar variant="error" message={error.graphQLErrors[0].message} />
    );

  const resendVerifiedEmail = async (email:string) => {
    try{
      await setResendVerifyEmail({
        variables: {
          email: email,
        },
      });
      props.showAlert('Successfully changed send verify email', 'success');
      await customersQueryRefetch()
    }catch(error){
      props.showAlert(error.message.replace('GraphQL error: ', ''), 'error');
    }
  };

  return <CustomersComponent
      resendVerifiedEmail={resendVerifiedEmail}
      users={users} />;
};

const usersQuery = gql`
  query usersQuery {
    users {
      id
      firstname
      lastname
      email
      phoneNo
      emailConfirmationNumber
      creditCard {
        id
      }
    }
  }
`;

export const Customers = graphql(usersQuery, {
  options: { fetchPolicy: 'network-only' },
})(CustomersComponent1);
