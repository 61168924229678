import { useState } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { MySnackBar } from '@src/components/MySnackBar';

const ResetPassword = (props: any): JSX.Element => {
  const styles = makeStyles((theme: Theme) =>
    createStyles({
      main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
          3,
        )}px`,
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        marginTop: theme.spacing(3),
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
      card: {
        maxWidth: 400,
      },
    }),
  );

  const value: any = {
    loading: false,
    password: '',
    passwordConfirm: '',
    process: 0,
  };

  const [stateValue, setStateValue] = useState(value);

  const onChangeHandler = (e) => {
    setStateValue({
      ...stateValue,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    setStateValue({
      ...stateValue,
      [e.target.name]: e.target.value,
    });

    e.preventDefault();
    setStateValue({ ...stateValue, loading: true });
    const { token } = queryString.parse(props.location.search);
    if (stateValue.password === stateValue.passwordConfirm) {
      try {
        await props.resetpassword({
          variables: {
            resetPasswordNumber: token,
            newPassword: stateValue.password,
          },
        });
        setStateValue({ ...stateValue, process: 1 });
      } catch (error) {
        setStateValue({ ...stateValue, process: 2 });
      }
    } else {
      setStateValue({ ...stateValue, process: 3 });
    }
  };

  const classes = styles();
  let snack1: any = null;
  if (stateValue.process === 1) {
    snack1 = (
      <div>
        <Grid className={classes.paper}>
          <MySnackBar
            variant="success"
            message="パスワードは変更されました。"
          />
        </Grid>
      </div>
    );
  } else if (stateValue.process === 2) {
    snack1 = (
      <div>
        <Grid>
          <MySnackBar
            variant="error"
            message="エラーが発生しました。最初からやり直してください。"
          />
        </Grid>
      </div>
    );
  } else {
    snack1 = (
      <div>
        <Grid>
          <MySnackBar
            variant="error"
            message="入力したパスワード(確認用)が一致しません。最初からやり直してください。"
          />
        </Grid>
      </div>
    );
  }
  const snackComponent = stateValue.process === 0 ? null : snack1;
  const { loading } = stateValue;
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper elevation={2} className={classes.paper}>
        <img
          alt="img"
          style={{ width: '100%' }}
          src="https://s3-ap-northeast-1.amazonaws.com/share-buggy/babydoor_sharebuggy.png"
        />
        <Typography component="h1" variant="h5" />
        {stateValue.process === 0 ? (
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">パスワード</InputLabel>
              <Input
                name="password"
                type="password"
                autoFocus
                placeholder="パスワード"
                onChange={onChangeHandler}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">パスワード(確認用)</InputLabel>
              <Input
                name="passwordConfirm"
                type="password"
                placeholder="パスワード(確認用)"
                onChange={(e) => onChangeHandler(e)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
              onClick={onSubmit}>
              パスワードを変更
            </Button>
          </form>
        ) : null}
        <Grid>{snackComponent}</Grid>
      </Paper>
    </main>
  );
};

const resetpassword = gql`
  mutation CHANGEPASSWORDRESET(
    $resetPasswordNumber: String!
    $newPassword: String!
  ) {
    changePasswordReset(
      resetPasswordNumber: $resetPasswordNumber
      newPassword: $newPassword
    )
  }
`;

export const ResetPasswordComponent = graphql(resetpassword, {
  name: 'resetpassword',
})(ResetPassword);
