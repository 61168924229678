import { useDeleteBabyCarModal } from '@src/components/BabyCars/components/DeleteBabyCarModal';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { BabyCarAddButton } from '@src/components/BabyCars/components/BabyCarAddButton';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { useState } from 'react';
import { babyCarsQuery, portsQuery } from '@src/generated/graphql';

enum CleaningStatus {
  NOT_RENTABLE = 'NOT_RENTABLE',
  NOT_USED = 'NOT_USED',
  USED = 'USED',
}

export const BabyCarList = ({
  portsQueryData,
  addBabyCar,
  babyCarsQueryData,
  cleanBabyCar,
  deleteBabyCar,
  updateBabyCar,
  isSystemAdmin = false,
}: {
  portsQueryData: portsQuery | undefined;
  addBabyCar: () => Promise<void>;
  babyCarsQueryData: babyCarsQuery | undefined;
  cleanBabyCar: any;
  deleteBabyCar: any;
  isSystemAdmin: boolean;
  updateBabyCar: any;
}): JSX.Element => {
  const [DeleteBabyCarModal, setDeleteBabyCarId] = useDeleteBabyCarModal({
    deleteBabyCar,
  });

  const [searchedPort, setSearchedPort] = useState<string>('');
  const [statePort, setPortState] = useState<string>('ポート名');

  const handleChange = (e) => {
    setSearchedPort(e.target.value[0]);
    setPortState(e.target.value[1]);
  };
  const babyCarPortId = babyCarsQueryData?.babyCars.map(
    (baby) => baby?.door?.port?.id,
  );

  const columns = [
    {
      title: 'ポート',
      dataIndex: ['door', 'port', 'name'],
      key: 'portName',
    },
    {
      title: 'ベビーカー番号',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'ドア番号',
      dataIndex: ['door', 'code'],
      key: 'doorCode',
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '清掃状態',
      dataIndex: 'cleaningStatus',
      key: 'cleaningStatus',
      render: (cleaningStatus: any) => {
        let status = '';
        if (cleaningStatus === 'USED') {
          status = '使用済';
        } else if (cleaningStatus === 'NOT_USED') {
          status = '清掃済';
        } else if (cleaningStatus === 'NOT_RENTABLE') {
          status = '使用不可';
        }
        return status;
      },
    },
    {
      title: '清掃完了',
      key: 'toClean',
      render: (_, babyCar) => (
        <>
          <Button
            type="primary"
            disabled={
              babyCar.status === 'OUTSIDE' ||
              babyCar.cleaningStatus === 'NOT_USED'
            }
            onClick={() => cleanBabyCar(babyCar.id)}>
            清掃完了
          </Button>
          <Button
            type="primary"
            disabled={
              babyCar.status === 'OUTSIDE' ||
              babyCar.cleaningStatus === 'NOT_RENTABLE'
            }
            onClick={() =>
              updateBabyCar(babyCar.id, CleaningStatus.NOT_RENTABLE)
            }>
            使用不可
          </Button>
        </>
      ),
    },
  ];
  if (isSystemAdmin) {
    columns.push({
      title: 'Delete',
      key: 'delete',
      render: (_, babyCar) => (
        <Button danger onClick={() => setDeleteBabyCarId(babyCar.id)}>
          <DeleteOutlined />
        </Button>
      ),
    });
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <h1>
        ベビーカー
        {isSystemAdmin ? <BabyCarAddButton addBabyCar={addBabyCar} /> : null}
      </h1>
      <div
        style={{
          display: 'flex',
          padding: '20px',
          alignItems: 'flex-end',
          height: '100px',
        }}>
        <h1 style={{ marginRight: '10px' }}>ポートを選択</h1>
        <FormControl
          style={{ width: '250px', marginRight: '10px' }}
          variant="filled">
          <InputLabel htmlFor="ports-select">{statePort}</InputLabel>
          <Select
            value={babyCarPortId}
            onChange={handleChange}
            input={<OutlinedInput name={statePort} id="ports-select" />}>
            <MenuItem value="">
              <em>ALL</em>
            </MenuItem>
            {portsQueryData?.ports.map((ports) => (
              <MenuItem value={[ports?.id, ports?.name]}>
                {ports?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <DeleteBabyCarModal />
      {searchedPort ? (
        <>
          <Table
            dataSource={babyCarsQueryData?.babyCars.filter(
              (baby) => baby?.door?.port?.id === searchedPort,
            )}
            columns={columns}
          />
        </>
      ) : (
        <>
          <Table dataSource={babyCarsQueryData?.babyCars} columns={columns} />
        </>
      )}
    </div>
  );
};
