import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MySnackBar } from '@src/components/MySnackBar';
import { BabyCarList } from '@src/components/BabyCars/BabyCarList';
import { useMutation, useQuery } from '@apollo/client';
import { babyCarsQuery, portsQuery } from '@src/generated/graphql';

const portsQuery1 = gql`
  query portsQuery {
    ports {
      id
      name
    }
  }
`;

const babyCarsQuery1 = gql`
  query babyCarsQuery {
    babyCars {
      id
      code
      door {
        id
        code
        port {
          id
          name
        }
      }
      status
      cleaningStatus
    }
  }
`;

const babyCarMutation = gql`
  mutation BABYCAR($babyCarId: ID!) {
    babyCarCleaned(babyCarId: $babyCarId) {
      id
      code
      status
      cleaningStatus
    }
  }
`;
const addBabyCar = gql`
  mutation ADD_BabyCar($code: String!) {
    addBabyCar(code: $code) {
      id
    }
  }
`;

const deleteBabyCar = gql`
  mutation deleteBabyCar($babyCarId: ID!) {
    deleteBabyCar(babyCarId: $babyCarId) {
      id
    }
  }
`;

const updateBabyCar = gql`
  mutation updateBabyCar($babyCarId: ID!, $data: BabyCarUpdateInput!) {
    updateBabyCar(babyCarId: $babyCarId, data: $data) {
      cleaningStatus
    }
  }
`;

export const BabyCarsComponent = (props) => {
  const [setDeleteBabyCar] = useMutation(deleteBabyCar);
  const [setAddBabyCar] = useMutation(addBabyCar);
  const {
    data: babyCarsQueryData,
    loading: babyCarsQueryLoading,
    error: babyCarsQueryError,
    refetch: babyCarsQueryRefetch,
  } = useQuery<babyCarsQuery>(babyCarsQuery1, { fetchPolicy: 'network-only' });
  const { data: portsQueryData } = useQuery<portsQuery>(portsQuery1, {
    fetchPolicy: 'network-only',
  });
  const [cleanBabyCar] = useMutation(babyCarMutation);
  const [setUpdateBabyCar] = useMutation(updateBabyCar);

  const addBabyCar1 = async () => {
    await setAddBabyCar({
      variables: {
        code: '',
      },
    });
    await babyCarsQueryRefetch();
  };

  const cleanBabyCar1 = async (babyCarId: string) => {
    try {
      await cleanBabyCar({
        variables: {
          babyCarId,
        },
      });
      props.showAlert('Successfully changed BabyCar status', 'success');
      await babyCarsQueryRefetch();
    } catch (error: any) {
      props.showAlert(error.message.replace('GraphQL error: ', ''), 'error');
    }
  };

  const deleteBabyCar1 = async (babyCarId: string) => {
    await setDeleteBabyCar({
      variables: {
        babyCarId,
      },
    });
    babyCarsQueryRefetch();
  };

  const updateBabyCar1 = async (babyCarId, cleaningStatus) => {
    const alert = confirm('清掃状況を変更してもよろしいですか？');
    if (alert) {
      await setUpdateBabyCar({
        variables: {
          babyCarId,
          data: { cleaningStatus },
        },
      });
      babyCarsQueryRefetch();
    }
  };

  if (babyCarsQueryLoading) return <CircularProgress />;
  if (babyCarsQueryError)
    return (
      <MySnackBar
        variant="error"
        message={babyCarsQueryError.graphQLErrors[0].message}
      />
    );

  return (
    <BabyCarList
      addBabyCar={addBabyCar1}
      babyCarsQueryData={babyCarsQueryData}
      cleanBabyCar={cleanBabyCar1}
      deleteBabyCar={deleteBabyCar1}
      updateBabyCar={updateBabyCar1}
      isSystemAdmin={props.isSystemAdmin}
      portsQueryData={portsQueryData}
    />
  );
};
