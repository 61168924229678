import { OutlinedInput, Select, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const timeTheme = createTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiIconButton: {
      colorSecondary: {
        color: '#ff7875',
      },
      root: {
        padding: '5px',
        borderRadius: '50px',
      },
    },
    MuiFormControl: {
      marginDense: {
        marginBottom: '0px',
      },
    },
    MuiSelect: {
      select: {
        width: 'Calc(100% - 40px)',
      },
      icon: {
        color: '#ff7875',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: '2px',
        borderColor: '#FFFFFF',
      },
      inputMarginDense: {
        paddingTop: '10px',
        paddingBottom: '3px',
        paddingLeft: '5px',
        paddingRight: '2px',
      },
      input: {
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '5px',
        paddingRight: '2px',
      },
    },
  },
});
const inputTimeStyle = {
  width: '19vmin',
  height: '32px',
  marginTop: '1px',
  marginBottom: '1px',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  borderRadius: '3px',
};

export const FacilityWeekTimes = ({
  textInputRef,
  inputError,
  weeklyInitData,
  weekTimesHandleChange,
  count,
  values,
}: any) => {
  const inputWeeklyStyle = {
    width: '15vmin',
    height: '32px',
    marginTop: '1px',
    marginBottom: '1px',
    marginRight: '10px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    borderRadius: '3px',
  };
  return (
    <div>
      <MuiThemeProvider theme={timeTheme}>
        <div>
          <div
            style={{
              marginBottom: '0.5em',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div>
              <div
                style={{
                  marginBottom: '0.5em',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div>
                  <Select
                    native
                    id={`weekday${count}`}
                    name={'weekday'}
                    value={values?.weekTimes?.[count]?.weekday}
                    onChange={(e) => {
                      weekTimesHandleChange(e);
                    }}
                    input={
                      <OutlinedInput
                        style={inputWeeklyStyle}
                        id="outlined-age-native-simple"
                      />
                    }>
                    {weeklyInitData?.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    inputRef={textInputRef.current?.[count]}
                    style={inputTimeStyle}
                    value={values?.weekTimes?.[count]?.time}
                    onChange={(e) => {
                      weekTimesHandleChange(e);
                    }}
                    id={`weekday${count}`}
                    placeholder="00:00~00:00"
                    name={'time'}
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      style: { fontSize: 13 },
                      maxLength: 11,
                      pattern: '^[0-9:_~]+$',
                    }}
                  />
                  {inputError[`weekday${count}`] ? (
                    <div style={{ color: '#FF979A' }}>
                      "00:00~00:00"の形式で入力してください
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    </div>
  );
};
