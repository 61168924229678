import { useState } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { MySnackBar } from '@src/components/MySnackBar';

const EmailConfirmation = (props: any): JSX.Element => {
  const styles = makeStyles((theme: Theme) =>
    createStyles({
      main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
          3,
        )}px`,
      },
      snackbar: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
          3,
        )}px`,
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        marginTop: theme.spacing(3),
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
      card: {
        maxWidth: 400,
      },
    }),
  );
  const renderSnack = ({ state, classes }: any) => {
    if (state.process === 0) return null;
    if (state.process === 1)
      return (
        <div>
          <Grid className={classes.snackbar}>
            <MySnackBar
              variant="success"
              className={classes.margin}
              message="メールアドレスは認証されました。"
            />
          </Grid>
        </div>
      );
    return (
      <div>
        <Grid className={classes.snackbar}>
          <MySnackBar
            variant="error"
            className={classes.margin}
            message="エラーが発生しました。"
          />
        </Grid>
      </div>
    );
  };

  const value: any = {
    loading: false,
    process: 0,
  };

  const [stateValue, setStateValue] = useState(value);

  const emailConfirm = async (e) => {
    e.preventDefault();

    const { token } = queryString.parse(props.location.search);
    try {
      const res = await props.emailConfirm({
        variables: {
          token,
        },
      });
      if (res) {
        setStateValue({ loading: true, process: 1 });
      }
    } catch (error) {
      setStateValue({ loading: true, process: 2 });
    }
  };

  const classes = styles();
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper elevation={2} className={classes.paper}>
        <img
          alt="img"
          style={{ width: '100%' }}
          src="https://s3-ap-northeast-1.amazonaws.com/share-buggy/babydoor_sharebuggy.png"
        />
        <Typography component="h1" variant="h6">
          会員登録はまだ完了していません。
        </Typography>
        {stateValue.process === 0 ? (
          <form className={classes.form}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={stateValue.loading}
              className={classes.submit}
              onClick={emailConfirm}>
              メールアドレスを認証する
            </Button>
          </form>
        ) : null}
        <Grid>
          {renderSnack({
            classes,
            state: stateValue,
          })}
        </Grid>
      </Paper>
    </main>
  );
};

const emailConfirm = gql`
  mutation EMAILCONFIRM($token: String!) {
    userConfirm(confirmationNumber: $token) {
      id
    }
  }
`;

export const EmailConfirmationComponent = graphql(emailConfirm, {
  name: 'emailConfirm',
})(EmailConfirmation);
