import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FacilitiesComponent } from '@src/components/Facilities/component';
import {
  addFacility,
  deleteFacility,
  facilitiesQuery,
  facilitiesQuery_facilities,
  updateFacility,
} from '@src/generated/graphql';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { useState } from 'react';
import { validationSchema } from './validation';
type weekTimeType = {
  id: string;
  weekday: string | null;
  time: string | null;
  __typename?: string;
};
export const Facilities = (props: any) => {
  const [editFacilityId, setEditFacilityId] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    data: facilitiesData,
    refetch: facilitiesDataRefetch,
    loading: facilitiesLoading,
  } = useQuery<facilitiesQuery>(FACILITY_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const [addFacilityMutation] = useMutation<addFacility>(ADDFACILITY);
  const [deleteFacilityMutation] = useMutation<deleteFacility>(DELETEFACILITY);
  const [updateFacilityMutation] = useMutation<updateFacility>(UPDATE_FACILITY);

  const facilityValues = (faciOption?: facilitiesQuery_facilities) => {
    return {
      id: faciOption?.id || '',
      name: faciOption?.name || '',
      subName: faciOption?.subName || null,
      category: faciOption?.category || null,
      description: faciOption?.description || null,
      zipCode: faciOption?.zipCode || null,
      address: faciOption?.address || '',
      longitude: faciOption?.longitude || 0,
      latitude: faciOption?.latitude || 0,
      station: faciOption?.station || '',
      openYearRound: faciOption?.openYearRound || null,
      openingHourNote: faciOption?.openingHourNote || null,
      imageUrl1: faciOption?.imageUrl1 || null,
      imageUrl2: faciOption?.imageUrl2 || null,
      imageUrl3: faciOption?.imageUrl3 || null,
      washBasin: faciOption?.washBasin || null,
      milkWater: faciOption?.milkWater || null,
      microwave: faciOption?.microwave || null,
      diapersPail: faciOption?.diapersPail || null,
      babyChair: faciOption?.babyChair || null,
      weightScale: faciOption?.weightScale || null,
      vendingMachine: faciOption?.vendingMachine || null,
      privateRoom: faciOption?.privateRoom || null,
      menOK: faciOption?.menOK || null,
      facilityNote: faciOption?.facilityNote || null,
      weekTimes: faciOption?.weekTimes || null,
    };
  };
  const initialValues: facilitiesQuery_facilities = {
    ...facilityValues(),
  };
  const modalOpenFunc = async () => {
    setIsModalOpen(true);
  };
  const modalCloseFunc = async () => {
    setIsModalOpen(false);
    props.history.push('/facilities');
  };

  const onSubmit = async (
    values: facilitiesQuery_facilities,
    formikHelpers: FormikHelpers<facilitiesQuery_facilities>,
  ) => {
    const { id, weekTimes, ...remainWeekTimes } = values;
    const weekTimeObj = values.weekTimes?.map((weekTime) => {
      const { id, __typename, ...remain } = weekTime as weekTimeType;
      return remain;
    });
    const filterdWeekTimeObj = weekTimeObj?.filter((fil) => fil.weekday);

    if (!editFacilityId) {
      try {
        formikHelpers.setSubmitting(true);
        await addFacilityMutation({
          variables: {
            data: {
              ...{
                weekTimes: !values.openYearRound ? filterdWeekTimeObj : null,
              },
              ...remainWeekTimes,
            },
          },
        });
        props.history.push('/facilities');
        facilitiesDataRefetch();
      } catch (e) {
        console.log(e, 'addfacilty');
      } finally {
        setIsModalOpen(false);
        facilitiesDataRefetch();
        formikHelpers.setSubmitting(false);
      }
    } else if (editFacilityId) {
      try {
        await updateFacilityMutation({
          variables: {
            facilityId: editFacilityId,
            data: {
              ...{
                weekTimes: !values.openYearRound ? filterdWeekTimeObj : null,
              },
              ...remainWeekTimes,
            },
          },
        });
        props.history.push('/facilities');
        facilitiesDataRefetch();
      } catch (e) {
        console.log(e, 'addfacilty');
      } finally {
        setIsModalOpen(false);
        setEditFacilityId('');
        facilitiesDataRefetch();
        formikHelpers.setSubmitting(false);
      }
    }
  };

  const updateFacilityImageSoftDelete = async ({ facilityId, imageUrlKey }) => {
    try {
      await updateFacilityMutation({
        variables: {
          facilityId: facilityId,
          data: {
            [imageUrlKey]: '',
          },
        },
      });
    } catch (e) {
      console.log(e, 'error');
    } finally {
      await facilitiesDataRefetch();
    }
  };
  const deleteFacility = async (facilityId) => {
    const rt = confirm('この施設情報が削除されますがよろしいでしょうか？');
    if (rt == true) {
      try {
        await deleteFacilityMutation({
          variables: {
            facilityId,
          },
        });
      } catch (e) {
        console.log(e, 'deleteFacilityError');
      } finally {
        await facilitiesDataRefetch();
      }
    }
  };
  const facilitiesDataRefetchFunc = async () => {
    await facilitiesDataRefetch();
  };
  if (facilitiesLoading) return <CircularProgress />;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      <FacilitiesComponent
        key={editFacilityId ? editFacilityId : 'facility'}
        modalOpenFunc={modalOpenFunc}
        modalCloseFunc={modalCloseFunc}
        isModalOpen={isModalOpen}
        editFacilityId={editFacilityId}
        setEditFacilityId={setEditFacilityId}
        facilities={facilitiesData}
        deleteFacility={deleteFacility}
        updateFacilityImageSoftDelete={updateFacilityImageSoftDelete}
        facilityValues={facilityValues}
        facilitiesDataRefetchFunc={facilitiesDataRefetchFunc}
      />
    </Formik>
  );
};

const FACILITY_QUERY = gql`
  query facilitiesQuery {
    facilities {
      id
      name
      subName
      category
      description
      zipCode
      address
      longitude
      latitude
      station
      openYearRound
      openingHourNote
      imageUrl1
      imageUrl2
      imageUrl3
      washBasin
      milkWater
      microwave
      diapersPail
      babyChair
      weightScale
      vendingMachine
      privateRoom
      menOK
      facilityNote
      weekTimes {
        id
        weekday
        time
      }
    }
  }
`;
const ADDFACILITY = gql`
  mutation addFacility($data: AddFacilityInput!) {
    addFacility(data: $data) {
      id
    }
  }
`;
const DELETEFACILITY = gql`
  mutation deleteFacility($facilityId: ID!) {
    deleteFacility(facilityId: $facilityId) {
      id
    }
  }
`;
const UPDATE_FACILITY = gql`
  mutation updateFacility($facilityId: ID!, $data: FacilityUpdateInput) {
    updateFacility(facilityId: $facilityId, data: $data) {
      id
    }
  }
`;
